import {
  Box,
  Avatar,
  Skeleton,
  Card,
  CardHeader,
  CardActions,
  Button,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { CreateExperienceDialog } from './components/CreateExperienceDialog';
import { useEffect, useState } from 'react';
import useExperiences from '../../../queryHelpers/useExperiences';
import { routes } from '../../../routes';
import { useAdminOutletContext } from '../AdminLayout';
import { ExperienceVM } from '../../../viewModels';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import { getBookLink, shareBookLink } from '../../../helpers/linkHelpers';
import { AdaptiveShareIcon } from '../../../components/AdaptiveShareIcon';
import { useTranslation } from 'react-i18next';

export default function ExperiencesPage() {
  const navigate = useNavigate();
  const [openCreateExperience, setOpenCreateExperience] = useState(false);
  const outletContext = useAdminOutletContext();
  const { t } = useTranslation();
  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Services'),
      },
      BottomAppBar: {
        fab: {
          icon: <AddIcon />,
          title: t('Create service'),
          onClick: () => setOpenCreateExperience(true),
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetched } = useExperiences();
  if (!isFetched) {
    return (
      <>
        <Skeleton variant="circular" width={50} height={50}>
          <Avatar />
        </Skeleton>
        <Skeleton />
      </>
    );
  }

  const handleEditClick = (experience: ExperienceVM) => {
    navigate(routes.admin.experienceDetail(experience.id!));
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: 2,
          pl: 2,
          pr: 2,
          pb: 2,
        }}
      >
        {data!.map((experience: ExperienceVM, index) => (
          <Card key={index}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                    fontSize: 20,
                    bgcolor: 'primary.main',
                  }}
                >
                  {experience.summary.title[0].toUpperCase()}
                </Avatar>
              }
              title={experience.summary.title}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AccessTimeIcon
                  sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }}
                />
                <Typography variant="body2" color="text.secondary">
                  {experience.summary.duration} {t('minutes')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LinkIcon
                  sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }}
                />
                <Typography
                  variant="body2"
                  component="a"
                  href={getBookLink(experience)}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'primary.main', textDecoration: 'none' }}
                >
                  {t('Booking page')}
                </Typography>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
              <Box>
                <Tooltip title={t('Share')}>
                  <IconButton onClick={() => shareBookLink(experience)}>
                    <AdaptiveShareIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={() => handleEditClick(experience)}
              >
                {t('Edit')}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>

      <CreateExperienceDialog
        open={openCreateExperience}
        onClose={() => setOpenCreateExperience(false)}
      />
    </>
  );
}
