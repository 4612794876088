export const queryKeys = {
  experiences: 'experiences',
  bookings: 'bookings',
  users: 'users',
  accounts: 'accounts',

  availableTimeSlots: (
    experienceId?: string,
    year?: number,
    month?: number,
    ignoreBookingId?: string,
  ) =>
    ['availableTimeSlots', experienceId, year, month, ignoreBookingId].filter(
      Boolean,
    ),
};
