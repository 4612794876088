import React from 'react';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import BookingClientEditor from './BookingClientEditor';
import * as yup from 'yup';
import { useEditForm } from '../../hooks/useEditForm';
import useBooking from '../../queryHelpers/useBooking';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import { EmailOutlined } from '@mui/icons-material';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

type BookingClientViewEditProps = {
  bookingId: string;
};

const BookingClientFormSchema = yup
  .object({
    name: yup.string().required(i18n.t('Введите имя')),
    phone: yup
      .string()
      .min(11, i18n.t('Enter full phone number'))
      .required(i18n.t('Enter phone number')),
  })
  .required();

export type BookingClientFormInputs = {
  name: string;
  phone: string;
};

const BookingClientViewEdit: React.FC<BookingClientViewEditProps> = (props) => {
  const { t } = useTranslation();
  const bookingQuery = useBooking(props.bookingId);
  const appForm = useEditForm<BookingClientFormInputs>({
    defaultValues: {
      name: bookingQuery.data?.name || '',
      phone: bookingQuery.data?.phone || '',
    },
    formSchema: BookingClientFormSchema,
  });

  const upsertBookingMutation = useUpsertBooking();

  const onSubmit = (data: BookingClientFormInputs) => {
    upsertBookingMutation.mutate({
      ...data,
      id: props.bookingId,
    });
  };

  return (
    <ViewEditControl
      title={t('Client')}
      editMode="inline"
      viewFields={[
        {
          icon: <PersonIcon />,
          label: t('Name'),
          value: bookingQuery.data?.name || '',
        },
        {
          icon: <EmailOutlined />,
          label: t('Email'),
          value: bookingQuery.data?.email || '',
        },
        {
          icon: <LocalPhoneIcon />,
          label: t('Phone'),
          value: bookingQuery.data?.phone ? '+' + bookingQuery.data?.phone : '',
        },
      ]}
      editor={<BookingClientEditor form={appForm.form} />}
      editorIsValid={appForm.form.formState.isValid}
      onCancel={appForm.handleCancel}
      onSave={appForm.form.handleSubmit(onSubmit)}
    />
  );
};

export default BookingClientViewEdit;
