import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import BookingForm from '../../components/bookingForm/BookingForm';
import BottomNav from '../../components/BottomNav';
import useExperience from '../../queryHelpers/useExperience';
import { Box } from '@mui/material';
import { useUserContext } from '../../contexts/UserContext';

interface BookingProcessPageProps {}

const BookingProcessPage: React.FC<BookingProcessPageProps> = (props) => {
  const { experienceId } = useParams<{ experienceId: string }>();
  const navigate = useNavigate();
  const handleSave = (id: string) => {
    navigate(`/booking/${id}/success`);
  };

  const { user } = useUserContext();
  const experienceQuery = useExperience({
    id: experienceId,
    link: experienceId,
  });
  const bottomNavRef = useRef<HTMLDivElement>(null);
  const [bottomNavHeight, setBottomNavHeight] = React.useState(0);

  useEffect(() => {
    if (bottomNavRef.current) {
      setBottomNavHeight(bottomNavRef.current.clientHeight);
    }
  }, [bottomNavRef, experienceQuery.data, user]);

  return (
    <>
      {experienceId && (
        <BookingForm experienceId={experienceId} onSave={handleSave} />
      )}

      {user &&
        experienceQuery &&
        experienceQuery.data?.accountId === user.accountId && (
          <>
            <Box sx={{ pb: 2, pt: (theme) => bottomNavHeight + 'px' }} />
            <BottomNav ref={bottomNavRef} />
          </>
        )}
    </>
  );
};

export default BookingProcessPage;
