import toast from 'react-hot-toast';
import { routes } from '../routes';
import { ExperienceVM } from '../viewModels';

export function getBookLink(experience: ExperienceVM) {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return `${baseUrl}${routes.booking(
    experience.summary.link || experience.id,
  )}`;
}

export function canShareBookLink() {
  return navigator.share;
}

export async function shareBookLink(
  experience: ExperienceVM,
  copyToClipboard: boolean = false,
) {
  if (navigator.share && !copyToClipboard) {
    try {
    await navigator.share({
      title: 'Ссылка для онлайн записи',
      text: `Ссылка для онлайн записи на "${experience!.summary.title}"`,
      url: getBookLink(experience!),
      });
    } catch (error) {
      console.error('Error sharing link:', error);
    }
    return;
  } else {
    navigator.clipboard.writeText(
      `Ссылка для онлайн записи на "${
        experience!.summary.title
      }"\n${getBookLink(experience!)}`,
    );
    toast.success(
      'Ссылка скопирована. Теперь вы можете вставить ее в чат с клиентом и отправить.'
    );
  }
}
