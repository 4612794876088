import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material';
import React from 'react';

export const PaperBackground: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const parentTheme = useTheme();
  const newTheme = createTheme(parentTheme, {
    palette: {
      background: {
        default: parentTheme.palette.background.paper,
      },
    },
  });

  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
