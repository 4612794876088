import React, { useEffect } from 'react';
import { Container } from '@mui/material';

import ViewDetailsSectionList from '../../../styled/ViewDetailsSectionList';
import SettingsUserViewEdit from '../../../components/settingsUser/SettingsUserViewEdit';
import SettingsSubscriptionViewEdit from '../../../components/SettingsSubscriptionViewEdit';
import { useAdminOutletContext } from '../AdminLayout';
import { LoadingButton } from '@mui/lab';
import SettingsGoogleViewEdit from '../../../components/SettingsGoogleViewEdit';
import { useUserContext } from '../../../contexts/UserContext';
import { useTranslation } from 'react-i18next';

interface SettingsPageProps {}

const SettingsPage: React.FC<SettingsPageProps> = () => {
  const outletContext = useAdminOutletContext();
  const { user, authPending, logout } = useUserContext();
  const { t } = useTranslation();

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Settings'),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Container>
      <ViewDetailsSectionList>
        <SettingsUserViewEdit userId={user?.id!} />
        <SettingsSubscriptionViewEdit userId={user?.id!} />
        <SettingsGoogleViewEdit userId={user?.id!} />
        <LoadingButton
          loading={authPending}
          color="error"
          sx={{ mt: 2 }}
          onClick={handleLogout}
        >
          {t('Logout')}
        </LoadingButton>
      </ViewDetailsSectionList>
    </Container>
  );
};

export default SettingsPage;
