import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { createNewExperienceVM } from '../../viewModels';

interface EditorDialogProps {
  open: boolean;
  onClose: () => void;
  editor: React.ReactNode;
}

export const EditorDialog: React.FC<EditorDialogProps> = ({
  open,
  onClose,
  editor,
}) => {
  const navigate = useNavigate();

  const handleSave = () => {
    const newExperience = createNewExperienceVM();
    navigate('/admin/experience/' + newExperience.id);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: -1 }}>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1 }}>
            Создать услугу
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>{editor}</DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={handleSave}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
