import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import AppService from '../services/appService';
import { experienceDbToVM } from './mapping';

export default function useExperience(props?: {
  id?: string;
  link?: string;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: [queryKeys.experiences, props?.id ?? props?.link],
    enabled: props?.enabled,
    queryFn: async () => {
      const service = new AppService();
      const experience = props?.id
        ? await service.getExperienceById(props!.id!)
        : await service.getExperienceByLink(props!.link!);

      if (!experience) {
        return null;
      }

      return experienceDbToVM(experience);
    },
    select: (data) => {
      return data;
    },
  });
}
