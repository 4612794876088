import { calendar_v3 } from "@googleapis/calendar/v3";

type Gapi = typeof gapi;

export function loadAuth2(gapi: Gapi) {
  return new Promise((resolve) => {
    gapi.load('auth2', resolve);
  });
}

export function oauth2Authorize(
  gapi: Gapi,
  options: gapi.auth2.AuthorizeConfig,
) {
  return new Promise((resolve) => {
    gapi.auth2.authorize(options, resolve);
  });
}

export function loadClient(gapi: Gapi) {
  return new Promise((resolve) => {
    gapi.load('client', resolve);
  });
}

export function googleAuthInstance() {
  return gapi.auth2?.getAuthInstance();
}

export function googleCalendar() : calendar_v3.Calendar {
  return (gapi.client as any)?.calendar;
}

export async function initGapi(gapi: Gapi) {

  await loadAuth2(gapi);
  // https://github.com/google/google-api-javascript-client/issues/401
  await gapi.auth2.init({
    scope: 'https://www.googleapis.com/auth/calendar',
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  });

  await loadClient(gapi);
  await gapi.client.init({
    scope: 'https://www.googleapis.com/auth/calendar',
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  });

  await gapi.client.load('calendar', 'v3');
}
