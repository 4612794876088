import React, { RefCallback } from 'react';
import { Slider } from '@mui/material';

const scale = [
  10, 20, 30, 40, 50, 60, 90, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660,
  720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440, 2880,
  4320, 5760, 7200, 8640, 10080, 11520, 12960, 14400, 15840, 17280, 18720,
  20160,
];

function calculateScale(value: number) {
  return scale[value];
}

function inverseScale(value: number) {
  return scale.indexOf(value);
}

type TimeSliderProps = {
  value?: number;
  onChange?: (event: Event, value: number | number[], activeThumb: number) => void;
  ref?: RefCallback<any>;
};

const TimeSlider: React.FC<TimeSliderProps> = (props) => {
  const handleChange = (event: Event, value: number | number[], activeThumb: number) => {
    if (props.onChange) {
      if (Array.isArray(value)) {
        props.onChange(event, value.map(v => calculateScale(v)), activeThumb);
      } else {
        props.onChange(event, calculateScale(value), activeThumb);
      }
    }
  };

  return (
    <Slider
      value={props.value ? inverseScale(props.value) : undefined}
      min={0}
      max={scale.length - 1}
      scale={calculateScale}
      onChange={handleChange}
      ref={props.ref}
    />
  );
};

export default TimeSlider;
