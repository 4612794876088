import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { DeleteExperienceRequest } from 'shared-ts/functions.dto';
import { DeleteExperience } from '../firebaseFunctions';

function useDeleteExperience(props: { id: string }) {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, void, unknown>({
    mutationFn: async () => {
      const updateRequest: DeleteExperienceRequest = {
        id: props.id,
      };
      await DeleteExperience(updateRequest);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.experiences]
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.availableTimeSlots(props.id)
      });
    },
  });

  return mutation;
}

export default useDeleteExperience;
