import { DateTimeSlotVM } from '../viewModels';

export const getTimeSlotsForSelection = (
  timeSlots: DateTimeSlotVM[],
  duration: number,
): DateTimeSlotVM[] => {
  const splitSlots: DateTimeSlotVM[] = [];
  timeSlots.forEach((slot) => {
    let start = slot.start;
    while (start.plus({ minutes: duration }) <= slot.end) {
      splitSlots.push({ start, end: start.plus({ minutes: duration }) });
      start = start.plus({ minutes: duration });
    }
  });
  return splitSlots;
};
