import React, { useEffect } from 'react';
import { Button } from '@mui/material';

declare global {
  interface Window {
    cp: any;
  }
}

type CloudPaymentsWidgetProps = {
  publicId: string; // Your CloudPayments public ID
  amount: number; // Amount to be charged
  description: string; // Description of the payment
  accountId: string; // Account ID of the payer
  onSuccess: (data: any) => void; // Callback for successful payment
  onFail: (reason: any) => void; // Callback for failed payment
};

const CloudPaymentsWidget: React.FC<CloudPaymentsWidgetProps> = ({
  publicId,
  amount,
  description,
  accountId,
  onSuccess,
  onFail,
}) => {
  useEffect(() => {
    // Load the CloudPayments widget script
    const script = document.createElement('script');
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on unmount
    };
  }, []);

  const onSubmit = (data: any) => {
    const widget = new window.cp.CloudPayments();
    widget.pay(
      'auth',
      {
        publicId: publicId,
        description: description,
        amount: amount,
        currency: 'RUB',
        accountId: accountId,
        skin: 'mini',
      },
      {
        onSuccess: (options: any) => {
          onSuccess(options);
        },
        onFail: (reason: any) => {
          onFail(reason);
        },
      },
    );
  };

  return (
    <Button
      type="button"
      onClick={onSubmit}
      variant="contained"
      color="primary"
    >
      Pay
    </Button>
  );
};

export default CloudPaymentsWidget;
