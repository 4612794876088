// TimeSlotEditor.tsx
import React from 'react';
import { Box, List, ListItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { TimeRangeSelector } from './TimeRangeSelector';

interface TimeSlotEditorProps {
  selectedSlot?: { start: number; end: number };
  onDelete: (() => void) | null;
  onClose: () => void;
  onDone: (slot: {start: number, end: number}) => void;
}

const TimeSlotEditor: React.FC<TimeSlotEditorProps> = ({
  selectedSlot,
  onDelete,
  onClose,
  onDone,
}) => {

  const [selectedRange, setSelectedRange] = React.useState<{
    start: number;
    end: number;
  } | undefined>(selectedSlot);

  return (
    <Box sx={{ p: 3, display: 'flex' }}>
      <Box sx={{ ml: -1 }}>
        <TimeRangeSelector
          startTime={selectedSlot?.start ?? 0}
          endTime={selectedSlot?.end ?? 0}
          onTimeRangeChange={(start, end) => setSelectedRange({ start, end })}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          pl: 2,
          mb: -1,
          mr: -1,
        }}
      >
        <List sx={{ p: 0 }}>
          {onDelete && <ListItem sx={{ p: 0 }}>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </ListItem>}
          <ListItem sx={{ p: 0 }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <IconButton onClick={() => onDone(selectedRange!)}>
              <DoneIcon />
            </IconButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default TimeSlotEditor;
