export function GetTotalMinsSince12AM(hours: number, minutes: number): number {
  return hours * 60 + minutes;
}

export function timeToString(time: number) {
  const hours = Math.floor(time / 60);
  const minutes = (time - hours * 60);
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

export function ToDate(totalMinutesSince12AM: number) {
  const hours = Math.floor(totalMinutesSince12AM / 60);
  const minutes = totalMinutesSince12AM % 60;

  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
}
