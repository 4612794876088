import { DateTimeSlotVM, TimeSlotVM } from '../viewModels';
import { DateTime } from 'luxon';

export function convertToTimeSlots(slots: DateTimeSlotVM[]): TimeSlotVM[] {
  return slots.map((slot) => ({
    start: slot.start.hour * 60 + slot.start.minute,
    end: slot.end.hour * 60 + slot.end.minute,
  }));
}

export function convertToDateTimeSlots(
  date: DateTime,
  slots: TimeSlotVM[],
): DateTimeSlotVM[] {
  return slots.map((slot) => {
    let startDate = DateTime.fromMillis(date.toMillis()).startOf('day');
    let endDate = DateTime.fromMillis(date.toMillis()).startOf('day');

    // Set start time
    startDate = startDate.set({
      minute: slot.start,
    });

    // Set end time based on the slot range
    if (slot.end === 0) {
      // If end is 0, set to next day at 00:00
      endDate = endDate.set({
        day: endDate.day + 1,
        hour: 0,
        minute: 0,
      });
    } else {
      endDate = endDate.set({
        minute: slot.end,
      });
    }

    return {
      start: startDate,
      end: endDate,
    };
  });
}
