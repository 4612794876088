import React from 'react';
import ViewEditControl from './viewEditControl/ViewEditControl';
import useUser from '../queryHelpers/useUser';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';
import AddBalanceIcon from '@mui/icons-material/AddCardOutlined';
import { pluralCredits } from '../helpers/pluralHelpers';
import useAccount from '../queryHelpers/useAccount';
import config from '../config';
import CloudPaymentsWidget from './cloudPayments/CloudPaymentsWidget';
import { useTranslation } from 'react-i18next';

type SubscriptionViewEditProps = {
  userId: string;
};

const SettingsSubscriptionViewEdit: React.FC<SubscriptionViewEditProps> = (
  props,
) => {
  const { t } = useTranslation();
  const userQuery = useUser(props.userId);
  const accountQuery = useAccount(userQuery.data?.accountId);
  const account = accountQuery.data;

  const handleSuccess = (data: any) => {
    console.log('Payment successful:', data);
  };

  const handleFail = (reason: any) => {
    console.error('Payment failed:', reason);
  };

  return (
    <ViewEditControl
      title={t('Subscription')}
      editMode="inline"
      viewEditIcon={<AddBalanceIcon />}
      viewFields={[
        {
          icon: <AccessTimeIcon />,
          label: t('Next charge'),
          value:
            account?.paidAccessUntil &&
            pluralCredits(config.subscriptionCost) +
              ', ' +
              account?.paidAccessUntil,
        },
        {
          icon: <AccountBalanceIcon />,
          label: t('Balance'),
          value: pluralCredits(account?.balance || 0),
        },
      ]}
      editor={
        <CloudPaymentsWidget
          publicId="pk_74e544cc76bcf0cea86db12f42884"
          amount={100}
          description="Payment for services"
          accountId={userQuery.data?.id!}
          onSuccess={handleSuccess}
          onFail={handleFail}
        />
      }
      onCancel={() => {}}
      onSave={async () => {}}
    />
  );
};

export default SettingsSubscriptionViewEdit;
