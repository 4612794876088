import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';
import AppFullScreenDialog, {
  AppFullScreenDialogProps,
} from '../../../../components/AppFullScreenDialog';
import ExperienceSelector, {
  ExperienceSelectorFormInputs,
} from '../../../../components/ExperienceSelector';
import * as yup from 'yup';
import { BookingVM } from '../../../../viewModels';
import { useEditForm } from '../../../../hooks/useEditForm';
import BookingClientEditor from '../../../../components/bookingClient/BookingClientEditor';
import { BookingClientFormInputs } from '../../../../components/bookingClient/BookingClientViewEdit';
import BookingScheduleEditor from '../../../../components/bookingSchedule/BookingScheduleEditor';
import { BookingScheduleFormInputs } from '../../../../components/bookingSchedule/BookingScheduleViewEdit';
import FieldsSection from '../../../../components/FieldsSection';
import ViewDetailsSectionList from '../../../../styled/ViewDetailsSectionList';
import useExperience from '../../../../queryHelpers/useExperience';
import useUpsertBooking from '../../../../queryHelpers/useUpsertBooking';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

type CreateBookingDialogProps = {} & Pick<
  AppFullScreenDialogProps,
  'formParams' | 'isPending'
>;

const Schema = yup
  .object({
    experienceId: yup.string().required(i18n.t('Select service')),
    name: yup.string().required(i18n.t('Enter name')),
    phone: yup
      .string()
      .min(11, i18n.t('Enter full phone number'))
      .required(i18n.t('Enter phone number')),
    // date: yup.string().required('Выберите дату'),
    timeSlot: yup.object().required(i18n.t('Select time')),
  })
  .required();

type CreateBookingFormInputs = Omit<
  BookingVM,
  'id' | 'experience' | 'createdIn'
>;

export const CreateBookingDialog: React.FC<CreateBookingDialogProps> = (
  props,
) => {
  const { t } = useTranslation();
  const appForm = useEditForm<CreateBookingFormInputs>({
    formSchema: Schema,
    defaultValues: {
      experienceId: '',
      name: '',
      phone: '',
      date: null,
      timeSlot: null,
    },
  });
  const watchExperienceId = appForm.form.watch('experienceId');

  const experienceQuery = useExperience({
    id: watchExperienceId,
    enabled: !!watchExperienceId,
  });

  const upsertBookingMutation = useUpsertBooking();

  const handleSubmitted = async () => {
    await appForm.form.trigger();
    if (!appForm.form.formState.isValid) {
      return;
    }

    await upsertBookingMutation.mutateAsync(appForm.form.getValues(), {
      onSuccess: () => {
        toast.success(t('Booking created successfully'));
        props.formParams.onClose();
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <AppFullScreenDialog
      formParams={{
        open: props.formParams.open,
        onClose: () => {
          appForm.handleCancel();
          props.formParams.onClose();
        },
      }}
      title={t('New booking')}
      submitLabel={t('Create')}
      onSubmitted={handleSubmitted}
      isPendingSubmit={upsertBookingMutation.isPending}
      isPending={experienceQuery?.isLoading}
    >
      <Box sx={{p: 4}}>
        <ViewDetailsSectionList>
          <FieldsSection title={t('Service')} containerClassName="p-2">
            <ExperienceSelector
              form={
                appForm.form as unknown as UseFormReturn<ExperienceSelectorFormInputs>
              }
            />
          </FieldsSection>
          {watchExperienceId && (
            <>
              <FieldsSection title={t('Date and time')} containerClassName="p-2">
                <BookingScheduleEditor
                  form={
                    appForm.form as unknown as UseFormReturn<BookingScheduleFormInputs>
                  }
                  experienceId={watchExperienceId}
                />
              </FieldsSection>
              <FieldsSection title={t('Client')} containerClassName="p-2">
                <BookingClientEditor
                  form={
                    appForm.form as unknown as UseFormReturn<BookingClientFormInputs>
                  }
                />
              </FieldsSection>
            </>
          )}
        </ViewDetailsSectionList>
      </Box>
    </AppFullScreenDialog>
  );
};
