import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { format } from 'date-fns';
import { BookingFormFormInput } from './BookingForm';
import { Typography } from '@mui/material';
import { SummaryVM } from '../../viewModels';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../contexts/LanguageContex';

interface BookingConfirmationDialogProps {
  booking: BookingFormFormInput | null;
  experience: SummaryVM | null;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const BookingConfirmationDialog: React.FC<BookingConfirmationDialogProps> = (
  props,
) => {
  const { t } = useTranslation();
  // const [book, setBook] = useState<Book>({ title: '', author: '', isbn: '' });
  const { timezone } = useTimeZone();
  const { dateLocale } = useLanguage();

  const handleCancel = () => {
    props.onCancel();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Проверьте и подтвердите</DialogTitle>
      <DialogContent>
        {props.booking && props.experience && (
          <>
            <DialogContentText>
              <Typography variant="body1">
                {t('Appointment for "{service}" on {day} from {start} to {end}', {
                  service: props.experience.title,
                  day: format(new Date(props.booking.timeSlot!.start.setZone(timezone).toJSDate()), 'PPPP', { locale: dateLocale }),
                  start: props.booking.timeSlot!.start.setZone(timezone).toFormat('HH:mm'),
                  end: props.booking.timeSlot!.end.setZone(timezone).toFormat('HH:mm'),
                })}
              </Typography>
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('Back')}</Button>
        <Button onClick={handleConfirm}>{t('Confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingConfirmationDialog;
