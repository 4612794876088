import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useBooking from '../../../queryHelpers/useBooking';
import ViewDetailsSectionList from '../../../styled/ViewDetailsSectionList';
import BookingSummaryViewEdit from '../../../components/bookingSummary/BookingSummaryViewEdit';
import BookingClientViewEdit from '../../../components/bookingClient/BookingClientViewEdit';
import BookingScheduleViewEdit from '../../../components/bookingSchedule/BookingScheduleViewEdit';
import { useAdminOutletContext } from '../AdminLayout';
import { routes } from '../../../routes';
import { Delete } from '@mui/icons-material';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import useDeleteBooking from '../../../queryHelpers/useDeleteBooking';
import { useTranslation } from 'react-i18next';

interface BookingDetailPageProps {}

const BookingDetailPage: React.FC<BookingDetailPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useBooking(id);
  const outletContext = useAdminOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const deleteBookingMutation = useDeleteBooking({ booking: data! });
  const handleConfirmDelete = () => {
    deleteBookingMutation.mutate(undefined, {
      onSuccess: () => {
        navigate(routes.admin.bookings);
      },
    });
  };

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Appointment'),
        backButtonPath: routes.admin.bookings,
        actionItems: [
          {
            icon: <Delete />,
            label: t('Delete'),
            onClick: () => setOpenDeleteDialog(true),
          },
        ],
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) return null;
  return (
    <Container>
      <ViewDetailsSectionList>
        <BookingSummaryViewEdit bookingId={id!} />
        <BookingScheduleViewEdit bookingId={id!} />
        <BookingClientViewEdit bookingId={id!} />
      </ViewDetailsSectionList>

      <ConfirmationDialog
        title={t('Delete booking?')}
        description={t('Are you sure you want to delete the booking? This action cannot be undone. The client will receive a notification about the cancellation of the booking.')}
        open={openDeleteDialog}
        onConfirm={handleConfirmDelete}
        onCancel={() => setOpenDeleteDialog(false)}
      />
    </Container>
  );
};

export default BookingDetailPage;
