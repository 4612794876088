import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { UpdateAccount } from '../firebaseFunctions';

type Data = {
  accountId: string;
  deleteGoogleAccount?: boolean;
};

export default function useUpdateAccount() {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, Data, unknown>({
    mutationFn: async (data: Data) => {
      await UpdateAccount(data);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.accounts],
        exact: false,
      });
    },
  });

  return mutation;
}
