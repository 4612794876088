import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Fab,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Outlet,
  useOutletContext,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// Import icons for the bottom navigation
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import { routes } from '../../routes';
import { WorkOutline } from '@mui/icons-material';
import { AdaptiveArrowBackIcon } from '../../components/AdaptiveArrowBackIcon';
import { useTranslation } from 'react-i18next';

export type TopAppBarActionItem = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type TopAppBarProps = {
  title: string;
  backButtonPath?: string;
  actionItems?: TopAppBarActionItem[];
  fab?: BottomAppBarFab;
};

export type BottomAppBarFab = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type BottomAppBarProps = {
  fab?: BottomAppBarFab;
};

export type AppBarsProps = {
  TopAppBar?: TopAppBarProps;
  BottomAppBar?: BottomAppBarProps;
};

export type AdminOutletContext = {
  setAppBars: (props: AppBarsProps) => void;
};

export function useAdminOutletContext() {
  return useOutletContext<AdminOutletContext>();
}

export default function AdminLayout() {
  const containerRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      console.log(`Container size: ${offsetWidth} x ${offsetHeight}`);
    }
  }, []);

  const [appBarsProps, setAppBarProps] = useState<AppBarsProps | null>(null);

  const setAppBars = (props: AppBarsProps) => {
    setAppBarProps(props);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(routes.admin.bookings);
        break;
      case 1:
        navigate(routes.admin.experiences);
        break;
      case 2:
        navigate(routes.admin.settings);
        break;
    }
  };

  useEffect(() => {
    // Update the selected value based on the current path
    const path = location.pathname;
    if (path.includes(routes.admin.bookings)) setValue(0);
    else if (path.includes(routes.admin.experiences)) setValue(1);
    else if (path.includes(routes.admin.settings)) setValue(2);
  }, [location]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar
        component="nav"
        variant="elevation"
        elevation={0}
        sx={{
          backgroundColor: (theme) =>
            theme.components?.MuiAppBar?.defaultProps?.style?.backgroundColor,
          paddingTop: 'max(env(safe-area-inset-top), 0px)',
        }}
      >
        <Toolbar sx={{ position: 'relative' }}>
          <Box>
            {!!appBarsProps?.TopAppBar?.backButtonPath && (
              <IconButton
                color="inherit"
                aria-label="Back"
                onClick={() =>
                  navigate(appBarsProps!.TopAppBar!.backButtonPath!)
                }
              >
                <AdaptiveArrowBackIcon />
              </IconButton>
            )}
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {appBarsProps?.TopAppBar?.title || 'Contacts'}
          </Typography>
          <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
            {appBarsProps?.TopAppBar?.actionItems?.map((item, index) => (
              <IconButton
                key={index}
                color="inherit"
                aria-label={item.label}
                onClick={item.onClick}
              >
                {item.icon}
              </IconButton>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          mt: { xs: 7, sm: 8 },
          mb: 7,
          pt: { xs: 2, sm: 2 },
          pb: appBarsProps?.BottomAppBar?.fab ? 7 : 2,
        }}
      >
        <Box sx={{ pt: 'max(env(safe-area-inset-top), 0px)', pb: 'max(env(safe-area-inset-bottom), 0px)' }}>
          <Box component="main">
            <Outlet
              context={
                {
                  setAppBars,
                } satisfies AdminOutletContext
              }
            />
          </Box>
        </Box>
      </Box>

      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
          paddingBottom: 'max(env(safe-area-inset-bottom), 0px)',
          backgroundColor: (theme) =>
            theme.components?.MuiBottomNavigation?.defaultProps?.style
              ?.backgroundColor,
        }}
      >
        <BottomNavigation value={value} onChange={handleChange} showLabels>
          <BottomNavigationAction label={t('Appointments')} icon={<CalendarMonthIcon />} />
          <BottomNavigationAction label={t('Services')} icon={<WorkOutline />} />
          <BottomNavigationAction label={t('Settings')} icon={<SettingsIcon />} />
        </BottomNavigation>
      </AppBar>

      {appBarsProps?.BottomAppBar?.fab && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 70,
            right: 16,
            paddingBottom: 'max(env(safe-area-inset-bottom), 0px)',
          }}
        >
          <Fab
            color="primary"
            onClick={appBarsProps?.BottomAppBar.fab.onClick}
            title={appBarsProps?.BottomAppBar.fab.title}
            variant="extended"
          >
            {appBarsProps?.BottomAppBar.fab.icon}
            {appBarsProps?.BottomAppBar.fab.title}
          </Fab>
        </Box>
      )}

      {/* <BottomNav /> */}
    </Box>
  );
}
