import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { DeleteBooking } from '../firebaseFunctions';
import { DeleteBookingRequest } from 'shared-ts/functions.dto';
import { BookingVM } from '../viewModels';

function useDeleteBooking(props: { booking: BookingVM }) {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, void, unknown>({
    mutationFn: async () => {
      const deleteRequest: DeleteBookingRequest = {
        id: props.booking.id!,
      };
      await DeleteBooking(deleteRequest);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.bookings],
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.availableTimeSlots(props.booking.experienceId, props.booking.timeSlot?.start.year, props.booking.timeSlot?.start.month),
      });
    },
  });

  return mutation;
}

export default useDeleteBooking;
