import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

export interface SectionProps extends React.PropsWithChildren {
  title: string;
}

const Section: React.FC<SectionProps> = (props) => {
  return (
    <Box>
      <Typography variant="panelHeader" sx={{pl: 0.5, pb: 0.5}}>
        {props.title.toUpperCase()}
      </Typography>
      <Paper variant="outlined">{props.children}</Paper>
    </Box>
  );
};

export default Section;
