import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

type Props = {
  title: string;
  children: React.ReactNode;
  containerClassName?: string;
};

const FieldsSection: React.FC<Props> = (props) => {
  return (
    <Box>
      <Typography
        sx={{ fontWeight: '500', color: (theme) => theme.palette.primary.main }}
      >
        {props.title}
      </Typography>
      <Paper variant="outlined" className={props.containerClassName}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              py: 1,
              px: 2,
              width: 1,
              overflow: 'hidden',
              whiteSpace: 'break-spaces',
            }}
          >
            {props.children}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default FieldsSection;
