import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FooterSection = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-row items-center justify-center pt-6">
      <div
        style={{ maxWidth: 900 }}
        className="w-full flex md:flex-row flex-col px-12 py-2 justify-between items-center border-t"
      >
        <div className="flex flex-col" style={{ maxWidth: 300 }}>
          <Typography variant="body1">Minutus</Typography>
          <Typography variant="caption" className='hidden md:block'>
            {t('Minutus - is a cloud solution for fast and easy online booking')}
          </Typography>
        </div>
        <div className="flex flex-col text-center md:flex-row md:text-left">
          <a
            href="https://docs.google.com/document/d/1eTTc-kN4QdDsOiU8q9d83ms07gOoT-tIKeeIj5k5z3M/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="caption">
              {t('User agreement')}
            </Typography>
          </a>
          <a
            href="mailto:info@Minutus.ru"
            className="md:before:content-['•'] md:before:mx-1"
          >
            <Typography variant="caption">info@Minutus.ru</Typography>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
