import * as Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';

// Workaround: https://github.com/VKCOM/vkid-web-sdk/issues/24

export const generateCodeChallenge = (codeVerifier: string): string => {
  const hash = sha256(codeVerifier);
  const base64 = Base64.stringify(hash);
  return base64.replace(/=*$/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

const generateRandomString = (length: number): string => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// Function to set a cookie
const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

// Function to get a cookie by name
const getCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

let codeVerifier = '';
let codeVerifierTmp = getCookie('vk_code_verifier');
if (!codeVerifierTmp) {
  codeVerifierTmp = generateRandomString(43);
  setCookie('vk_code_verifier', codeVerifierTmp, 1); // Save for 1 day
}

codeVerifier = codeVerifierTmp!;

export { codeVerifier };
