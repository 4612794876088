import { ArrowBack, ArrowBackIos } from '@mui/icons-material';

export const AdaptiveArrowBackIcon: React.FC = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return <ArrowBack />; // Replace with actual Android share icon
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return <ArrowBackIos />; // Replace with actual iOS share icon
  } else {
    return <ArrowBack />; // Replace with actual Windows share icon
  }
};
