import {
  Avatar,
  Box,
  List,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
  ListItemButton,
} from '@mui/material';
import useBookings from '../../../queryHelpers/useBookings';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { PaperBackground } from '../../../styled/PaperBackground';
import { useAdminOutletContext } from '../AdminLayout';
import { Add } from '@mui/icons-material';
import { CreateBookingDialog } from './components/CreateBookingDialog';
import useExperiences from '../../../queryHelpers/useExperiences';
import { DateTime } from 'luxon';
import { useTimeZone } from '../../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../contexts/LanguageContex';

export default function BookingsPage() {
  const { timezone } = useTimeZone();
  const { data } = useBookings();
  const { dateLocale } = useLanguage();
  const { t } = useTranslation();
  const experiencesQuery = useExperiences();
  const groupedBookings = groupBy(data, (d) =>
    d.timeSlot.start.setZone(timezone).toISODate(),
  );
  const navigate = useNavigate();

  const [openCreateBooking, setOpenCreateBooking] = useState(false);
  const outletContext = useAdminOutletContext();

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Appointments'),
      },
      BottomAppBar: {
        fab: {
          title: t('Create booking'),
          icon: <Add />,
          onClick: () => setOpenCreateBooking(true),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data === undefined) return null;
  return (
    <>
      <PaperBackground>
        <List sx={{ with: '100%' }}>
          {Object.entries(groupedBookings).map(([date, bookings]) => (
            <React.Fragment key={date}>
              <ListSubheader>
                {DateTime.fromISO(date, { zone: timezone }).toFormat(
                  'dd MMMM',
                  { locale: dateLocale.code },
                )}
              </ListSubheader>
              {bookings!.map((booking, index) => (
                <ListItemButton
                  key={index}
                  sx={{ borderRadius: 2 }}
                  onClick={() =>
                    navigate(routes.admin.bookingDetail(booking.id))
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        fontSize: 20,
                      }}
                    >
                      {booking.name[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ pl: 1 }}
                    primary={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 0,
                        }}
                      >
                        <Box>
                          <Typography color={'black'}>
                            {`${experiencesQuery.data?.find(
                              (x) => x.id === booking.experienceId,
                            )?.summary.title}, ${booking.name} `}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Typography variant="body2">{`${booking.timeSlot.start
                            .setZone(timezone)
                            .toFormat('HH:mm')} -
                          ${booking.timeSlot.end
                            .setZone(timezone)
                            .toFormat('HH:mm')}`}</Typography>
                        </Box>
                      </Box>
                    }
                    secondary={`${booking.phone}${
                      booking.wishes ? ', ' + booking.wishes : ''
                    }`}
                  ></ListItemText>
                </ListItemButton>
              ))}
            </React.Fragment>
          ))}
        </List>
      </PaperBackground>

      <CreateBookingDialog
        formParams={{
          open: openCreateBooking,
          onClose: () => setOpenCreateBooking(false),
        }}
      />
    </>
  );
}
