import React, { useCallback, useEffect } from 'react';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useExperience from '../../../queryHelpers/useExperience';
import ViewDetailsSectionList from '../../../styled/ViewDetailsSectionList';
import SummaryViewEdit from '../../../components/experienceSummary/SummaryViewEdit';
import LimitSettingsViewEdit from '../../../components/experienceLimitSettings/LimitSettingsViewEdit';
import GoogleCalendarIntegrationViewEdit from '../../../components/experienceGoogleCalendar/GoogleCalendarIntegrationViewEdit';
import { TopAppBarActionItem, useAdminOutletContext } from '../AdminLayout';
import { routes } from '../../../routes';
import { Delete } from '@mui/icons-material';
import useDeleteExperience from '../../../queryHelpers/useDeleteExperience';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { shareBookLink } from '../../../helpers/linkHelpers';
import WeekDayAvailabilityViewEdit from '../../../components/experienceDateAvailability/DateAvailabilityViewEdit';
import { AdaptiveShareIcon } from '../../../components/AdaptiveShareIcon';
import { useTranslation } from 'react-i18next';

interface ExperienceDetailPageProps {
  /*...*/
}

const ExperienceDetailPage: React.FC<ExperienceDetailPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const experienceQuery = useExperience({ id: id, enabled: true });
  const outletContext = useAdminOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const deleteMutation = useDeleteExperience({
    id: id!,
  });

  const handleConfirmDelete = () => {
    deleteMutation.mutate(undefined, {
      onSuccess: () => {
        navigate(routes.admin.experiences);
      },
    });
  };

  const handleShare = useCallback(async () => {
    if (!experienceQuery.data) return;
    await shareBookLink(experienceQuery.data);
  }, [experienceQuery.data]);

  useEffect(() => {
    if (!experienceQuery.isFetched) {
      return;
    }

    const topBarActionItems: TopAppBarActionItem[] = [];
    topBarActionItems.push({
      icon: <AdaptiveShareIcon />,
      label: t('Share'),
      onClick: handleShare,
    });

    topBarActionItems.push({
      icon: <Delete />,
      label: t('Delete'),
      onClick: () => setOpenDeleteDialog(true),
    });

    outletContext.setAppBars({
      TopAppBar: {
        title: t('Service'),
        backButtonPath: routes.admin.experiences,
        actionItems: topBarActionItems,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceQuery.isFetched]);

  if (!experienceQuery.isFetched) {
    return <></>;
  }

  return (
    <>
      <Container>
        <ViewDetailsSectionList>
          <SummaryViewEdit experienceId={id!} />
          <WeekDayAvailabilityViewEdit experienceId={id!} />
          <LimitSettingsViewEdit experienceId={id!} />
          <GoogleCalendarIntegrationViewEdit experienceId={id!} />
        </ViewDetailsSectionList>

        <ConfirmationDialog
          title={t('Delete service?')}
          description={t('Are you sure you want to delete the service? This action cannot be undone.')}
          open={openDeleteDialog}
          onConfirm={handleConfirmDelete}
          onCancel={() => setOpenDeleteDialog(false)}
        />
      </Container>
    </>
  );
};

export default ExperienceDetailPage;
