import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import AppService from '../services/appService';
import { bookingDbToVM } from './mapping';
import { useUserContext } from '../contexts/UserContext';

export default function useBookings() {
  const { user } = useUserContext();

  return useQuery({
    queryKey: [queryKeys.bookings],
    queryFn: async () => {
      const service = new AppService();
      const bookings = await service.getBookingsByAccountId(user?.accountId!);
      return bookings.map((x) => bookingDbToVM(x));
    },
    select: (result) => {
      return result;
    },
  });
}
