import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { DateTime } from 'luxon';
import { useUserContext } from './UserContext';

interface TimeZoneContextType {
  timezone: string;
}

const TimeZoneContext = createContext<TimeZoneContextType | undefined>(
  undefined,
);

export const TimeZoneProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user } = useUserContext();

  const [timezone, setTimezone] = useState<string>(() => {
    return user?.timezone || DateTime.local().zoneName || 'UTC';
  });

  useEffect(() => {
    if (user?.timezone && user.timezone !== timezone) {
      setTimezone(user.timezone);
    }
  }, [timezone, user]);

  return (
    <TimeZoneContext.Provider value={{ timezone }}>
      {children}
    </TimeZoneContext.Provider>
  );
};

export const useTimeZone = () => {
  const context = useContext(TimeZoneContext);
  if (context === undefined) {
    throw new Error('useTimeZone must be used within a TimeZoneProvider');
  }
  return context;
};
