import React from 'react';
import {
  TextField,
  Grid,
  FormHelperText,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Label } from '../../styled/Label';
import { SettingsUserFormInputs } from './SettingsUserViewEdit';
import TimezoneSelector from '../TimezoneSelector';
import { useTranslation } from 'react-i18next';

interface UserSettingEditorProps {
  form: UseFormReturn<SettingsUserFormInputs>;
}

const SettingUserEditor: React.FC<UserSettingEditorProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>{t('Name')}</Label>
        <TextField
          size="small"
          error={!!props.form.formState.errors.name}
          {...props.form.register('name')}
          fullWidth
        />
        <FormHelperText error>
          {props.form.formState.errors.name?.message &&
            t(props.form.formState.errors.name?.message)}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Email')}</Label>
        <TextField
          size="small"
          error={!!props.form.formState.errors.email}
          {...props.form.register('email')}
          fullWidth
        />
        <FormHelperText error>
          {props.form.formState.errors.email?.message &&
            t(props.form.formState.errors.email?.message)}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Timezone')}</Label>
        <Controller
          name="timezone"
          control={props.form.control}
          render={({ field }) => (
            <TimezoneSelector
              value={field.value}
              onChange={(value: string) => field.onChange(value)}
              error={!!props.form.formState.errors.timezone}
            />
          )}
        />

        <FormHelperText error>
          {props.form.formState.errors.timezone?.message &&
            t(props.form.formState.errors.timezone?.message)}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Language')}</Label>
        <Controller
          name="language"
          control={props.form.control}
          render={({ field }) => (
            <Select
              size="small"
              error={!!props.form.formState.errors.language}
              value={field.value}
              onChange={(value) => field.onChange(value)}
              fullWidth
            >
              <MenuItem value="ru">{t('ru')}</MenuItem>
              <MenuItem value="en">{t('en')}</MenuItem>
            </Select>
          )}
        />
        <FormHelperText error>
          {props.form.formState.errors.language?.message &&
            t(props.form.formState.errors.language?.message)}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default SettingUserEditor;
