import React from 'react';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../hooks/useEditForm';
import useExperience from '../../queryHelpers/useExperience';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import { DateTimeSlotVM } from '../../viewModels';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { DateTime } from 'luxon';
import DateAvailabilityEditor from './DateAvailabilityEditor';
import { useTranslation } from 'react-i18next';

type WeekDayAvailabilityViewEditProps = {
  experienceId: string;
};

export const WeekDayAvailabilityFormSchema = yup.object({}).required();

export type WeekDayAvailabilityFormInputs2 = {
  availabilities: DateTimeSlotVM[];
};

const WeekDayAvailabilityViewEdit: React.FC<
  WeekDayAvailabilityViewEditProps
> = (props) => {
  const { t } = useTranslation();
  const experienceQuery = useExperience({ id: props.experienceId });
  const { timezone } = useTimeZone();

  const { form, handleCancel } = useEditForm<WeekDayAvailabilityFormInputs2>({
    defaultValues: {
      availabilities:
        experienceQuery.data?.availabilities.map((x) => ({
          start: x.start.setZone(timezone),
          end: x.end.setZone(timezone),
        })) ?? [],
    },
    formSchema: WeekDayAvailabilityFormSchema,
  });

  const [selectedDate, setSelectedDate] = React.useState<DateTime | undefined>(
    DateTime.now().setZone(timezone),
  );

  const updateUserSettingsMutation = useUpdateExperience(props.experienceId);

  const onSubmit = (data: WeekDayAvailabilityFormInputs2) => {
    updateUserSettingsMutation.mutate({
      availabilities: data.availabilities.map((slot) => ({
        start: slot.start.setZone(timezone, { keepLocalTime: true }),
        end: slot.end.setZone(timezone, { keepLocalTime: true }),
      })),
    });
  };

  return (
    <ViewEditControl
      title={t('Schedule')}
      view={
        <DateAvailabilityEditor
          // value={toLocalDateTimeSlots(experienceQuery.data?.availabilities ?? [], timezone)}
          form={form}
          editMode={false}
          selectedDate={selectedDate}
          onSelectedDateChange={(date) => setSelectedDate(date)}
        />
      }
      editor={
        <DateAvailabilityEditor
          form={form}
          editMode={true}
          selectedDate={selectedDate}
          onSelectedDateChange={(date) => setSelectedDate(date)}
        />
      }
      editMode="inline"
      editorIsValid={form.formState.isValid}
      onCancel={handleCancel}
      onSave={form.handleSubmit(onSubmit)}
    />
  );
};

export default WeekDayAvailabilityViewEdit;
