import React from 'react';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../hooks/useEditForm';
import useExperience from '../../queryHelpers/useExperience';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import LimitSettingsEditor from './LimitSettingsEditor';
import CoffeeIcon from '@mui/icons-material/CoffeeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';
import CompressIcon from '@mui/icons-material/CompressOutlined';
import Filter3Icon from '@mui/icons-material/Filter3Outlined';
import PendingActionsIcon from '@mui/icons-material/PendingActionsOutlined';
import {
  enabled,
  pluralBookings,
  pluralDays,
  pluralTime,
} from '../../helpers/pluralHelpers';
import { useTranslation } from 'react-i18next';

type LimitSettingsViewEditProps = {
  experienceId: string;
};

export const LimitSettingsFormSchema = yup.object({}).required();

export type LimitSettingsFormInputs = {
  futureBookingDaysLimit: number | null;
  minimumNoticePeriod: number | null;
  dailyBookingLimit: number | null;
  gapBetweenEvents: number | null;
  onlyConsecutiveReservations: boolean;
};

const LimitSettingsViewEdit: React.FC<LimitSettingsViewEditProps> = (props) => {
  const experienceQuery = useExperience({ id: props.experienceId });
  const { t } = useTranslation();
  const {
    form: {
      register,
      handleSubmit,
      watch,
      control,
      formState: { errors, isValid },
    },
    handleCancel,
  } = useEditForm<LimitSettingsFormInputs>({
    defaultValues: experienceQuery.data?.limitSettings,
    formSchema: LimitSettingsFormSchema,
  });

  const updateUserSettingsMutation = useUpdateExperience(props.experienceId);

  const onSubmit = (data: LimitSettingsFormInputs) => {
    updateUserSettingsMutation.mutate({
      limitSettings: data,
    });
  };

  return (
    <ViewEditControl
      title={t('Special conditions')}
      editMode="inline"
      viewFields={[
        {
          icon: <DateRangeIcon />,
          label: t('On how many days in advance the booking is open'),
          value: t('For {time}', {
            time: pluralDays(
              experienceQuery.data?.limitSettings.futureBookingDaysLimit!,
            ),
          }),
        },
        {
          icon: <PendingActionsIcon />,
          label: t('How long before the start is the appointment open'),
          value: t('{time} before', {
            time: pluralTime(
              experienceQuery.data?.limitSettings.minimumNoticePeriod!,
            ),
          }),
        },
        {
          icon: <Filter3Icon />,
          label: t('how many appointments in a day'),
          value: pluralBookings(
            experienceQuery.data?.limitSettings.dailyBookingLimit!,
          ),
        },
        {
          icon: <CoffeeIcon />,
          label: t('What is the gap between appointments'),
          value: Number(experienceQuery.data?.limitSettings.gapBetweenEvents)
            ? pluralTime(experienceQuery.data?.limitSettings.gapBetweenEvents!)
            : 'Без перерыва',
        },
        {
          icon: <CompressIcon />,
          label: 'Разрешать запись только рядом с другими записями',
          value: enabled(
            experienceQuery.data?.limitSettings.onlyConsecutiveReservations!,
          ),
        },
      ]}
      editor={
        <LimitSettingsEditor
          register={register}
          watch={watch}
          errors={errors}
          control={control}
        />
      }
      editorIsValid={isValid}
      onCancel={handleCancel}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default LimitSettingsViewEdit;
