import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { ExperienceVM } from '../viewModels';
import { experienceVMToDb } from './mapping';
import { CreateExperienceFunc } from '../firebaseFunctions';

export default function useCreateExperience() {
  const queryClient = useQueryClient();

  const mutation = useMutation<ExperienceVM, Error, ExperienceVM, unknown>({
    mutationFn: async (data: ExperienceVM) => {
      try {
        const response = await CreateExperienceFunc(experienceVMToDb(data));
        return { ...data, id: response.data.id };
      } catch (error) {
        throw new Error((error as Error).message);
      }
    },

    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.experiences],
        exact: false,
      }),
  });

  return mutation;
}
