import React, { useEffect } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { TimeSlotVM } from '../../viewModels';
import { timeToString } from '../../helpers/timeHelpers';

import TimeSlotEditor from './TimeSlotEditor';
import { useTranslation } from 'react-i18next';

interface TimeSlotSelectorProps {
  slots: TimeSlotVM[];
  helperText?: string;
  error?: boolean;
  onChange?: (slots: TimeSlotVM[]) => void;
}

const TimeSlotsEditor: React.FC<TimeSlotSelectorProps> = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedSlot, setSelectedSlot] = React.useState<TimeSlotVM | null>();
  const [slots, setSlots] = React.useState<TimeSlotVM[]>(props.slots);

  useEffect(() => {
    setSlots(props.slots);
  }, [props.slots]);

  const handleClickSlot = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    slot: TimeSlotVM,
  ) => {
    setAnchorEl(e.currentTarget);
    setSelectedSlot(slot);
  };

  const handleClickAdd = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(e.currentTarget);
    const maxEnd = slots.reduce((max, slot) => Math.max(max, slot.end), 0);
    setSelectedSlot({
      start: maxEnd === 0 ? 720 : maxEnd,
      end: maxEnd === 0 ? 720 : maxEnd,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSlot(null);
  };

  const handleDelete = () => {
    const newSlotCollection = slots.filter((slot) => slot !== selectedSlot);
    setSlots(newSlotCollection);
    if (props.onChange) {
      props.onChange(newSlotCollection);
    }
    handleClose();
  };

  const handleDone = (slot: { start: number; end: number }) => {
    const existingSlot = slots.find((slot) => slot === selectedSlot);
    if (existingSlot) {
      existingSlot.start = slot.start;
      existingSlot.end = slot.end;
      if (props.onChange) {
        props.onChange(slots);
      }
    } else {
      const newSlotCollection = [...slots, slot];
      setSlots(newSlotCollection);
      if (props.onChange) {
        props.onChange(newSlotCollection);
      }
    }

    handleClose();
  };

  return (
    <Box>
      <Stack
        direction="column"
        spacing={1}
        textAlign={'center'}
        flexWrap={'wrap'}
        useFlexGap
      >
        {props.onChange && (
          <>
            {slots
              .sort((a, b) => a.start - b.start)
              .map((slot) => (
                <Button
                  key={slot.start}
                  variant="outlined"
                  size="small"
                  onClick={(e) => handleClickSlot(e, slot)}
                >
                  {timeToString(slot.start) + ' - ' + timeToString(slot.end)}
                </Button>
              ))}

            <Button variant="outlined" size="small" onClick={handleClickAdd}>
              +
            </Button>
          </>
        )}
        {!props.onChange && (
          <>
            {slots
              .sort((a, b) => a.start - b.start)
              .map((slot) => (
                <Typography key={slot.start}>
                  {timeToString(slot.start) + ' - ' + timeToString(slot.end)}
                </Typography>
              ))}
            {slots.length === 0 && (
              <Typography>{t('No available slots')}</Typography>
            )}
          </>
        )}
      </Stack>
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}

      <Popover
        id={'test'}
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TimeSlotEditor
          selectedSlot={{
            start: selectedSlot?.start ?? 0,
            end: selectedSlot?.end ?? 0,
          }}
          onDelete={
            slots.find((slot) => slot === selectedSlot) ? handleDelete : null
          }
          onClose={handleClose}
          onDone={handleDone}
        />
      </Popover>
    </Box>
  );
};

export default TimeSlotsEditor;
