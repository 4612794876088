import {
  DefaultValues,
  FieldValues,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type UseEditorFormProps = {
  formSchema: yup.AnyObjectSchema;
  defaultValues: DefaultValues<any>;
};

export const useEditForm = <FormInputs extends FieldValues>(
  props: UseEditorFormProps,
) => {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: props.defaultValues,
    resolver: yupResolver(props.formSchema),
  });

  const handleCancel = () => {
    form.reset(props.defaultValues);
  };

  return {
    form: form as UseFormReturn<FormInputs>,
    handleCancel,
  };
};
