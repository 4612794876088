import { useEffect, useState } from 'react';
import { useUserContext } from '../../contexts/UserContext';
import useAccount from '../../queryHelpers/useAccount';
import useRefreshGoogleIntegration from '../../queryHelpers/useRefreshGoogleIntegration';

export const useGoogleAccessToken = (): {
  isPending: boolean;
  accessToken: string | null;
} => {
  const userContext = useUserContext();
  const account = useAccount(userContext.user?.accountId);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(true);
  const useRefreshGoogleIntegrationMutation = useRefreshGoogleIntegration();

  useEffect(() => {
    if (
      userContext.authPending ||
      account.isPending ||
      useRefreshGoogleIntegrationMutation.isPending
    ) {
      return;
    }

    if (!account.data) {
      setAccessToken(null);
      setIsPending(false);
      return;
    }

    const googleAccount = account.data.googleAccount;
    if (!googleAccount) {
      setAccessToken(null);
      setIsPending(false);
      return;
    }

    const minutesUntilExpiry =
      googleAccount.expiryDate.diffNow('minutes').minutes;

    if (minutesUntilExpiry < 5) {
      useRefreshGoogleIntegrationMutation.mutate({
        accountId: account.data.id!,
      });
      return;
    }

    setAccessToken(googleAccount.accessToken);
    setIsPending(false);

    // Set up a timer to refresh the token 5 minutes before expiry
    const refreshTimeout = setTimeout(
      () => {
        if (!account.data || !account.data.googleAccount) {
          return;
        }

        useRefreshGoogleIntegrationMutation.mutate({
          accountId: account.data!.id!
        });
      },
      (minutesUntilExpiry - 5) * 60 * 1000,
    );

    return () => clearTimeout(refreshTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account.data,
    userContext.authPending,
    account.isPending,
    useRefreshGoogleIntegrationMutation,
  ]);

  return {
    accessToken,
    isPending,
  };
};
