import { useQuery } from '@tanstack/react-query';
import { GetAvailableTimeSlots } from '../firebaseFunctions';
import { DateTimeSlotVM } from '../viewModels';
import { DateTime } from 'luxon';
import { queryKeys } from './queryKeys';

export default function useAvailableTimeSlots(experienceId: string, date: DateTime | null, ignoreBookingId?: string) {
  const year = date?.startOf('month').year;
  const month = date?.startOf('month').month;
  return useQuery<DateTimeSlotVM[], Error>({
    queryKey: queryKeys.availableTimeSlots(experienceId, year, month, ignoreBookingId),
    staleTime: 60 * 1000,
    queryFn: async () => {
      if (!year || !month) {
        throw new Error('Invalid date');
      }
      const result = await GetAvailableTimeSlots({ experienceId, year, month, ignoreBookingId });
      return result.data.availabilities.map(x => ({
        start: DateTime.fromMillis(x.start),
        end: DateTime.fromMillis(x.end),
      }));
    },
    // Enable the query only if both experienceId and date are provided
    enabled: !!experienceId && !!date,
  });
}
