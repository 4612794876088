import { Box } from '@mui/material';
import { styled } from '@mui/system';

const ViewDetailsSectionList  = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default ViewDetailsSectionList;
