import React from 'react';
import { Container, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../contexts/LanguageContex';

interface AppointmentConfirmationProps {}

const BookingConfirmationPage: React.FC<AppointmentConfirmationProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { timezone } = useTimeZone();
  const { dateLocale } = useLanguage();
  const { bookingId } = useParams<{ bookingId: string }>();
  const bookingQuery = useBooking(bookingId);
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId as string,
    enabled: !!bookingQuery.data,
  });

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 2 }}>
        {bookingQuery.data && experienceQuery.data ? (
          <>
            <Typography variant="h6" gutterBottom>
              {t('Congratulations!')}
            </Typography>
            <Typography variant="body1">
              {t(
                'Appointment for "{service}" on {day} from {start} to {end}',
                {
                  service: experienceQuery.data.summary.title,
                  day: format(
                    new Date(
                      bookingQuery.data.timeSlot.start
                        .setZone(timezone)
                        .toJSDate(),
                    ),
                    'PPPP',
                    { locale: dateLocale },
                  ),
                  start: bookingQuery.data.timeSlot.start
                    .setZone(timezone)
                    .toFormat('HH:mm'),
                  end: bookingQuery.data.timeSlot.end
                    .setZone(timezone)
                    .toFormat('HH:mm'),
                },
              )}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Paper>
    </Container>
  );
};

export default BookingConfirmationPage;
