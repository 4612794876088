import React from 'react';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import {
  EventAvailable,
  InsertInvitation,
  PersonAddAlt,
  VideoCallOutlined,
} from '@mui/icons-material';
import useExperience from '../../queryHelpers/useExperience';
import { Chip } from '@mui/material';
import { GoogleCalendarIntegrationEditor } from './GoogleCalendarIntegrationEditor';
import useGoogleCalendars from '../../queryHelpers/useGoogleCalendars';
import * as yup from 'yup';
import { GoogleCalendarSettingsVM } from '../../viewModels';
import { Nullable } from '../../types';
import { useEditForm } from '../../hooks/useEditForm';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import { useGoogleUserInfo } from '../googleClientProvider/useGoogleUserInfo';
import AttachGoogleAccountButton from '../AttachGoogleAccountButton';
import { useTranslation } from 'react-i18next';

// Add this line at the top of your file to declare the google property on the Window interface

const GoogleCalendarIntegrationFormSchema = yup
  .object({
    calendarIdForNewEvents: yup.string().nullable(),
    calendarIdsForAvailability: yup.array(yup.string()).nullable(),
    invitees: yup.array(yup.string()).nullable(),
  })
  .required();

export type GoogleCalendarIntegrationFormInputs =
  Nullable<GoogleCalendarSettingsVM>;

interface GoogleCalendarIntegrationViewEditProps {
  experienceId: string;
}

const GoogleCalendarIntegrationViewEdit: React.FC<
  GoogleCalendarIntegrationViewEditProps
> = ({ experienceId }) => {
  const { t } = useTranslation();
  const googleCalendars = useGoogleCalendars();
  const experience = useExperience({ id: experienceId });
  const googleUserInfo = useGoogleUserInfo();

  const appForm = useEditForm<GoogleCalendarIntegrationFormInputs>({
    defaultValues: experience.data?.googleCalendarSettings,
    formSchema: GoogleCalendarIntegrationFormSchema,
  });

  const updateUserSettingsMutation = useUpdateExperience(experienceId);

  const onSubmit = (data: GoogleCalendarIntegrationFormInputs) => {
    updateUserSettingsMutation.mutate({
      googleCalendarSettings: {
        calendarIdForNewEvents: data?.calendarIdForNewEvents,
        calendarIdsForAvailability:
          data?.calendarIdsForAvailability?.filter(
            (x) => x !== data?.calendarIdForNewEvents,
          ) ?? null,
        invitees: data?.invitees,
        createGoogleMeetLink: data?.createGoogleMeetLink ?? false,
      },
    });
  };

  return (
    <>
      {!googleUserInfo.data && <AttachGoogleAccountButton />}
      {googleUserInfo.data && (
        <ViewEditControl
          title={t('Google calendar')}
          editMode={'inline'}
          viewFields={[
            {
              icon: <InsertInvitation />,
              label: t('Create bookings in this calendar'),
              value:
                googleCalendars.data?.find(
                  (x) =>
                    x.id ===
                    experience.data?.googleCalendarSettings
                      ?.calendarIdForNewEvents,
                )?.summary ??
                experience.data?.googleCalendarSettings?.calendarIdForNewEvents,
            },
            {
              icon: <EventAvailable />,
              label: t('Check availability in these calendars'),
              value:
                experience.data?.googleCalendarSettings?.calendarIdsForAvailability?.map(
                  (x) => {
                    const calendar = googleCalendars.data?.find(
                      (y) => y.id === x,
                    );
                    return (
                      <Chip
                        size="small"
                        label={calendar ? calendar.summary : x}
                      />
                    );
                  },
                ) ?? [],
            },
            {
              icon: <PersonAddAlt />,
              label: t('Invite these participants'),
              value: experience.data?.googleCalendarSettings?.invitees?.map(
                (x) => <Chip size="small" label={x} />,
              ),
            },
            {
              icon: <VideoCallOutlined />, // New icon for the Google Meet link
              label: t('Create Google Meet for video calls'),
              value: experience.data?.googleCalendarSettings
                ?.createGoogleMeetLink
                ? t('Yes')
                : t('No'), // Display value
            },
          ]}
          editor={<GoogleCalendarIntegrationEditor form={appForm.form} />}
          editorIsValid={appForm.form.formState.isValid}
          onCancel={appForm.handleCancel}
          onSave={appForm.form.handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export default GoogleCalendarIntegrationViewEdit;
