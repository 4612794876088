import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { ExperienceVM } from '../viewModels';
import { UpdateExperienceRequest } from 'shared-ts/functions.dto';
import { UpdateExperienceFunc } from '../firebaseFunctions';
import { experienceVMToDb } from './mapping';

export default function useUpdateExperience(experienceId: string) {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, Partial<ExperienceVM>, unknown>({
    mutationFn: async (data: Partial<ExperienceVM>) => {
      const updateRequest: UpdateExperienceRequest = {
        ...experienceVMToDb(data),
        id: experienceId,
      };

      await UpdateExperienceFunc(updateRequest);
    },

    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.experiences],
      });

      if (variables.availabilities || variables.googleCalendarSettings) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.availableTimeSlots(experienceId),
        });
      }
    },
  });

  return mutation;
}
