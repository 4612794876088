import { Outlet } from 'react-router-dom';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import GoogleClientProvider from './components/googleClientProvider/GoogleClientProvider';
import { UserProvider } from './contexts/UserContext';
import { TimeZoneProvider } from './contexts/TimeZoneContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LanguageProvider, useLanguage } from './contexts/LanguageContex';

// let vkSdkInitialised = false;

// See https://reactcommunity.org/react-transition-group/with-react-router/
function App() {
  const queryClient = new QueryClient();

  // if (!vkSdkInitialised) {
  //   console.log(process.env.NODE_ENV);
  //   const redirectUrl = `${window.location.protocol}//${window.location.hostname}/vkAuthRedirect`;
  //   initVkSdk({
  //     app: parseInt(config.vkAppId), // Идентификатор приложения.
  //     redirectUrl: redirectUrl,
  //     state: 'dj29fnsadjsd81', // Произвольная строка состояния приложения.
  //     codeChallenge: generateCodeChallenge(codeVerifier), // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
  //     scope: 'email phone', // Список прав доступа, которые нужны приложению.
  //     mode: VKID.ConfigAuthMode.Redirect, // По умолчанию авторизация открывается в новой вкладке.
  //   });
  //   vkSdkInitialised = true;
  // }

  // const location = useLocation();

  // const { nodeRef } =
  //   routesRefs.find((route) => route.regexPath.test(location.pathname)) ?? {};

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <TimeZoneProvider>
          <LanguageProvider>
            <AppInner />
          </LanguageProvider>
        </TimeZoneProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

function AppInner() {
  const { dateLocale } = useLanguage();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateLocale}
    >
      <GoogleClientProvider>
        <ThemeProvider theme={theme}>
          <Toaster />
          <CssBaseline />
          <Outlet />
          {/* Page Navigation Animation is repeated when one page immediately redirects to another one.
To fix it, it may help to have the same nodeRef for both pages */}
          {/* <SwitchTransition>
  <CSSTransition
    key={location.pathname}
    nodeRef={nodeRef}
    timeout={300}
    classNames="page"
    unmountOnExit
  >
    {(_) => (
      <div ref={nodeRef as RefObject<HTMLDivElement>} className="page">
        {currentOutlet}
      </div>
    )}
  </CSSTransition>
</SwitchTransition> */}
        </ThemeProvider>
      </GoogleClientProvider>
    </LocalizationProvider>
  );
}

export default App;
