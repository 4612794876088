import React from 'react';
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { DateTimeSlotVM } from '../../viewModels';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';

interface TimeSlotSelectorProps {
  slots: DateTimeSlotVM[];
  onChange: (slot: DateTimeSlotVM) => void;
  value: DateTimeSlotVM | null;
  helperText?: string;
  error?: boolean;
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = (props) => {
  const { timezone } = useTimeZone();
  const { t } = useTranslation();
  const handleClick = (slot: DateTimeSlotVM) => {
    if (!props.onChange) {
      return;
    }
    props.onChange(slot);
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
        {props.slots.map((slot) => (
          <Button
            key={slot.start.toMillis()}
            disableElevation
            variant={_.isEqual(props.value, slot) ? 'contained' : 'text'}
            onClick={() => handleClick(slot)}
          >
            {slot.start.setZone(timezone).toFormat('HH:mm')}
            {_.isEqual(props.value, slot) ? ' - ' + slot.end.setZone(timezone).toFormat('HH:mm') : ''}
          </Button>
        ))}
      </Stack>
      {props.slots.length === 0 && (
        <Typography variant="caption">
          {t('No available slots')}
        </Typography>
      )}
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}
    </Box>
  );
};

export default TimeSlotSelector;
