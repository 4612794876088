import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TimeSelector from './TimeSelector';

interface TimeRangePickerProps {
  startTime: number;
  endTime: number;
  onTimeRangeChange: (startTime: number, endTime: number) => void;
}

export const TimeRangeSelector: React.FC<TimeRangePickerProps> = ({
  startTime,
  endTime,
  onTimeRangeChange,
}) => {
  const [selectedStartTime, setSelectedStartTime] = useState<number>(startTime);
  const [selectedEndTime, setSelectedEndTime] = useState<number>(endTime);

  useEffect(() => {
    setSelectedStartTime(startTime);
    setSelectedEndTime(endTime);
  }, [startTime, endTime]);

  const handleChangeStartTime = (value: number) => {
    setSelectedStartTime(value);
    if (value > selectedEndTime) {
      setSelectedEndTime(value);
    }
    onTimeRangeChange(value, selectedEndTime);
  };

  const handleChangeEndTime = (value: number) => {
    setSelectedEndTime(value);
    onTimeRangeChange(selectedStartTime, value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <TimeSelector
        value={selectedStartTime}
        onChange={handleChangeStartTime}
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ mx: 2 }}
      />
      <TimeSelector
        value={selectedEndTime}
        minTime={selectedStartTime}
        onChange={handleChangeEndTime}
      />
    </Box>
  );
};
