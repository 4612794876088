import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { User } from '../../../shared-ts/entities';
import { TelegramUser } from 'shared-ts/telegram.types';
import { AuthVkRequest } from 'shared-ts/functions.dto';
import { FirebaseAuth, TelegramAuth, VkAuth } from '../firebaseFunctions';
import {
  signInWithCustomToken,
  signOut,
  onAuthStateChanged,
  getAuth,
  sendEmailVerification,
  AdditionalUserInfo,
  User as FirebaseUser,
} from 'firebase/auth';
import { auth } from '../firebaseConfig';
import AppService from '../services/appService';
import useUpdateUserSettings, {
  UpdateUserSettingsData,
} from '../queryHelpers/useUpdateUserSettings'; // Import the hook
import { DateTime } from 'luxon'; // Import Luxon
import { CircularProgress, Box } from '@mui/material'; // Add this import
import useUser from '../queryHelpers/useUser';
import { getDefaultLanguage } from './LanguageContex';
import { routes } from '../routes';

interface UserContextType {
  user: User | null;
  authPending: boolean;
  authError: string | null;
  actualizeStatus: () => Promise<void>;
  loginCustom: (param: LoginParam) => Promise<void>;
  login: (
    firebaseUser: FirebaseUser,
    additionalUserInfo: AdditionalUserInfo,
  ) => Promise<void>;
  logout: () => Promise<void>;
}

export type LoginParam =
  | { tgUser: TelegramUser; vkUser?: never }
  | { tgUser?: never; vkUser: AuthVkRequest };

const UserContext = createContext<UserContextType | undefined>(undefined);

export async function getUser(): Promise<User | null> {
  const auth = getAuth();
  await auth.authStateReady();

  if (!auth.currentUser) {
    return null;
  }

  const appService = new AppService();
  const user = await appService.getUserById(auth.currentUser.uid);
  return user || null;
}

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [authPending, setAuthPending] = useState<boolean>(true);
  const [authError, setAuthError] = useState<string | null>(null);
  const userQuery = useUser(user?.id);

  useEffect(() => {
    if (userQuery.data) {
      setUser(userQuery.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuery.data]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async () => {
      setAuthPending(true);
      try {
        const user = await getUser();
        setUser(user);
        setAuthError(null);
      } catch (err) {
        setAuthError('Failed to fetch user data');
      } finally {
        setAuthPending(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const useUpdateUserSettingsMutation = useUpdateUserSettings(); // Initialize the hook

  const actualizeStatus = async () => {
    setAuthPending(true);
    try {
      const user = await getUser();
      setUser(user);
      setAuthError(null);
    } catch (err) {
      setAuthError('Failed to actualize user status');
    } finally {
      setAuthPending(false);
    }
  };

  const login = async (
    firebaseUser: FirebaseUser,
    additionalUserInfo: AdditionalUserInfo,
  ) => {
    setAuthPending(true);
    setAuthError(null);

    try {
      if (additionalUserInfo.isNewUser) {
        await sendEmailVerification(firebaseUser);
      }

      await FirebaseAuth({
        displayName: firebaseUser.displayName!,
        email: firebaseUser.email!,
        photoURL: firebaseUser.photoURL,
        isNewUser: additionalUserInfo.isNewUser,
      });

      const user = await getUser();
      setUser(user);

      const updateUserSettings: Pick<
        UpdateUserSettingsData,
        'timezone' | 'language'
      > = {};

      if (!user?.timezone) {
        updateUserSettings.timezone = DateTime.local().zoneName;
      }

      if (!user?.language) {
        updateUserSettings.language = getDefaultLanguage();
      }

      if (Object.keys(updateUserSettings).length > 0) {
        await useUpdateUserSettingsMutation.mutateAsync({
          id: user!.id,
          ...updateUserSettings,
        });
      }
    } catch (err) {
      setAuthError('Login failed');
    } finally {
      setAuthPending(false);
    }
  };

  const loginCustom = async (param: LoginParam) => {
    setAuthPending(true);
    setAuthError(null);
    try {
      const customTokenRs = param.tgUser
        ? await TelegramAuth(param.tgUser)
        : await VkAuth(param.vkUser);
      await signInWithCustomToken(auth, customTokenRs.data as string);
      const user = await getUser();
      setUser(user);

      // Check if timezone is set
      if (!user?.timezone) {
        const timezone = DateTime.local().zoneName; // Get the current timezone
        await useUpdateUserSettingsMutation.mutateAsync({
          id: user!.id,
          timezone: timezone,
        });
      }
    } catch (err) {
      setAuthError('Login failed');
    } finally {
      setAuthPending(false);
    }
  };

  const logout = async () => {
    setAuthPending(true);
    setAuthError(null);
    try {
      await signOut(auth);
      setUser(null);
      window.location.href = routes.login; // Navigate to the login page
    } catch (err) {
      setAuthError('Logout failed');
      setAuthPending(false);
    }
  };

  const value = {
    user,
    authPending,
    authError,
    actualizeStatus,
    loginCustom,
    login,
    logout,
  };

  return (
    <UserContext.Provider value={value}>
      {authPending ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
