import { Apartment, Videocam, Work } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ItemProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const Item: React.FC<ItemProps> = (props) => {
  return (
    <Card sx={{ width: 300 }} elevation={0}>
      <CardContent className="flex flex-col items-center justify-center text-center">
        <Box
          className="text-white rounded-full mb-4"
          sx={{ bgcolor: 'primary.main'}}
          style={{ fontSize: 30, width: 50, height: 50 }}
        >
          {props.icon}
        </Box>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="text-center"
          color="primary"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="text-center"
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const UseCasesSection = () => {
  const { t } = useTranslation();
  const items: ItemProps[] = [
    {
      title: t('Appointments and services'),
      description: t('Appointments and services.Description'),
      icon: <Work />,
    },
    {
      title: t('Booking rooms or equipment'),
      description: t('Booking rooms or equipment.Description'),
      icon: <Apartment />,
    },
    {
      title: t('Booking online meetings'),
      description: t('Booking online meetings.Description'),
      icon: <Videocam />,
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">
        {t('{number} ways to use Minutus', { number: items.length })}
      </Typography>
      <div className="flex flex-row flex-wrap justify-center gap-0 md:gap-6">
        {items.map((item) => (
          <Item
            key={item.title}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default UseCasesSection;
