import React from 'react';
import { TextField, Grid, Slider, FormHelperText } from '@mui/material';
import { pluralTime } from '../../helpers/pluralHelpers';
import { Label } from '../../styled/Label';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { SummaryFormInputs } from './SummaryViewEdit';
import { useTranslation } from 'react-i18next';

interface SummaryEditorProps {
  register: UseFormRegister<SummaryFormInputs>;
  errors: FieldErrors<SummaryFormInputs>;
  watch: UseFormWatch<SummaryFormInputs>;
  autoLink?: boolean;
}

const SummaryEditor: React.FC<SummaryEditorProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>{t('Title')}</Label>
        <TextField
          size="small"
          error={!!props.errors.title}
          {...props.register('title')}
          fullWidth
        />
        <FormHelperText error>{props.errors.title?.message && t(props.errors.title?.message)}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Description')}</Label>
        <TextField
          size="small"
          {...props.register('description')}
          multiline
          rows={3}
          fullWidth
        />
        <FormHelperText error>{props.errors.description?.message && t(props.errors.description?.message)}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Duration')} {pluralTime(props.watch('duration')!)} </Label>
        <Slider {...props.register('duration')} step={5} min={5} max={720} />
      </Grid>
      {!props.autoLink && (
        <Grid item xs={12}>
          <Label>{t('Link')}</Label>
          <TextField
            size="small"
            error={!!props.errors.link}
            {...props.register('link', {
              required: { value: true, message: t('Enter a link') },
            })}
            fullWidth
          />
          <FormHelperText>
            http://Minutus.com/book/{props.watch('link')}
          </FormHelperText>
          <FormHelperText error>{props.errors.link?.message && t(props.errors.link?.message)}</FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};

export default SummaryEditor;
