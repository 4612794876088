import { useMutation } from '@tanstack/react-query';
import * as VKID from '@vkid/sdk';
import { vkExchangeAuthorizationCode } from '../../clients/vkClient';

type UseVkAuthorizeParams = {
  config: VKID.ConfigData;
  code: string;
  deviceId: string;
  codeVerifier: string;
};

export default function useVkAuthorize() {
  return useMutation<
    Omit<VKID.TokenResult, 'id_token'>,
    unknown,
    UseVkAuthorizeParams
  >({
    mutationFn: async (data) => {
      return await vkExchangeAuthorizationCode({
        appId: data.config.app,
        codeVerifier: data.codeVerifier,
        code: data.code,
        deviceId: data.deviceId,
        redirectUrl: data.config.redirectUrl,
        state: data.config.state,
      });
    },
  });
}
