import { useQuery } from '@tanstack/react-query';
import { calendar_v3 } from '@googleapis/calendar/v3';
import { useGoogleAccessToken } from '../components/googleClientProvider/useGoogleAccessToken';
import { googleCalendar } from '../helpers/gapiHelpers';

export default function useGoogleCalendars() {
  const googleAccessToken = useGoogleAccessToken();

  return useQuery<calendar_v3.Schema$CalendarListEntry[]>({
    queryKey: ['google', 'calendars', { accessToken: googleAccessToken.accessToken }],
    enabled: !!googleAccessToken.accessToken,
    queryFn: async () => {
      const response = await googleCalendar().calendarList.list();
      return (response as any).result.items; // Adjust as necessary based on the response structure
    },
  });
}
