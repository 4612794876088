import { useEffect, useRef, useState } from 'react';
import * as firebaseui from 'firebaseui';
import './firebase-ui-auth.css';

const FIREBASEUI_CONTAINER_ID = 'firebaseui_container';

// Add missing types
interface ScriptState {
  value: 'loading' | 'error' | 'loaded';
  payload?: any;
}

function useScript(src: string) {
  const [state, setState] = useState<ScriptState>({
    value: 'loading',
    payload: undefined,
  });
  const script = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    script.current = document.createElement('script');
    script.current.src = src;
    script.current.async = true;

    const onScriptLoad = () => setState({ value: 'loaded' });

    const onScriptError = (e: ErrorEvent) => {
      script.current?.remove();
      setState({ value: 'error', payload: e });
    };

    script.current.addEventListener('load', onScriptLoad);
    script.current.addEventListener('error', onScriptError);

    document.body.appendChild(script.current);
    return () => {
      script.current!.removeEventListener('load', onScriptLoad);
      script.current!.removeEventListener('error', onScriptError);
    };
  }, [src]);

  return state;
}

// Add proper types for the FirebaseUIAuth props
interface FirebaseUIAuthProps {
  auth: any; // Replace 'any' with your Firebase Auth type if available
  config: object;
  lang: string;
  version: string;
  rtl?: boolean;
  firebase: any; // Replace 'any' with your Firebase type if available
}

function FirebaseUIAuth({
  auth,
  config,
  lang,
  version,
  firebase,
}: FirebaseUIAuthProps) {
  const state = useScript(
    `https://www.gstatic.com/firebasejs/ui/${version}/firebase-ui-auth__${lang}.js`,
  );
  const container = useRef<HTMLDivElement>(null);
  const app = useRef<any>(null); // Replace 'any' with your Firebase App type if available

  useEffect(() => {
    if (firebase) window.firebase = firebase;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.value === 'loading') return;
    if (state.value === 'error') throw state.payload;
    if (!container.current) return;

    (async () => {
      if (app.current) {
        await app.current.delete();
      }
      container.current!.innerHTML = '';

      // Get existing UI instance or create new one
      let ui: firebaseui.auth.AuthUI | null =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(auth);

      // Start the UI
      if (!ui.isPendingRedirect()) {
        ui.start(`#${FIREBASEUI_CONTAINER_ID}`, config);
      }

      // Store reference to the temporary app
      try {
        app.current = window.firebase.app('[DEFAULT]-firebaseui-temp');
      } catch (error) {
        console.warn('FirebaseUI temporary app not found:', error);
      }
    })();
  }, [auth, config, state.payload, state.value]);

  return (
    <div ref={container} id={FIREBASEUI_CONTAINER_ID} />
  );
}

// Add type declarations for window properties
// declare global {
//   interface Window {
//     firebase: any; // Replace 'any' with your Firebase type if available
//     firebaseui: {
//       auth: {
//         AuthUI: {
//           getInstance(): any;
//           new (auth: any): any;
//         };
//       };
//     };
//   }
// }

export default FirebaseUIAuth;
