import * as VKID from '@vkid/sdk';

export type VkTokenResult = Omit<VKID.TokenResult, 'id_token'>;

export async function vkExchangeAuthorizationCode(data: {
  appId: number;
  codeVerifier: string;
  code: string;
  deviceId: string;
  redirectUrl: string;
  state?: string;
}): Promise<VkTokenResult> {
  const response = await fetch('https://id.vk.com/oauth2/auth', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: data.appId,
      code_verifier: data.codeVerifier,
      redirect_uri: data.redirectUrl,
      code: data.code,
      device_id: data.deviceId,
      state: data.state,
    } as any).toString(),
  });

  return (await response.json()) as unknown as VkTokenResult;
}
