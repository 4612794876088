import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { routes } from '../../routes';
import { useUserContext } from '../../contexts/UserContext';
import firebase from 'firebase/compat/app';

import { auth } from '../../firebaseConfig';
import { AdditionalUserInfo, User } from 'firebase/auth';
import FirebaseUIAuth from './firebaseUI/FirebaseUIAuth';
import { useLanguage } from '../../contexts/LanguageContex';

interface LoginPageProps {
  // Define your props here
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const userContext = useUserContext();
  const { language } = useLanguage();

  const firebaseAuthConfig = firebase.auth && {
    callbacks: {
      uiShown: function () {
        // This is what should happen when the form is full loaded. In this example, I hide the loader element.
        document.getElementById(
          'firebaseui-auth-loader-container',
        )!.style.display = 'none';
      },
      signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
        var user: User = authResult.user;
        var additionalUserInfo: AdditionalUserInfo =
          authResult.additionalUserInfo;



        (async () => {
          await userContext.login(user, additionalUserInfo);
        })();

        return false;
      },
    },
    // signInSuccessUrl: '/login', // This is where should redirect if the sign in is successful.
    signInOptions: [
      // This array contains all the ways an user can authenticate in your application. For this example, is only by email.
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
    ],
    // signInFlow: 'redirect',
    tosUrl: 'https://www.example.com/terms-conditions', // URL to you terms and conditions.
    privacyPolicyUrl: function () {
      // URL to your privacy policy
      window.location.assign('https://www.example.com/privacy-policy');
    },
  };

  return (
    <>
      {userContext.user?.accountId && <Navigate to={routes.admin.index} />}
      {userContext.user && !userContext.user.accountId && (
        <Navigate to={routes.loginAccount} />
      )}

      <Box
        sx={{ height: '100vh' }}
        className="flex justify-center items-center"
      >
        <Box sx={{ mt: '-150px' }} className="flex flex-col space-y-2">
          <Box
            component={'img'}
            src="/images/logo.svg"
            style={{ height: '150px' }}
            alt="Logo"
            className="pb-4"
          />

          {!userContext.authPending && !userContext.user && (
            <FirebaseUIAuth
              lang={language}
              version="6.1.0"
              auth={auth}
              config={firebaseAuthConfig}
              firebase={firebase}
            />
          )}

          <div id="firebaseui-auth-container">
            {/* Centering the auth container */}
          </div>
          <div id="firebaseui-auth-loader-container">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          </div>
          {/* <div className="flex flex-col pb-4 items-center">
            <Typography variant="h4">Вход в Minutus</Typography>
            <Typography variant="body1">Войдите используя</Typography>
          </div> */}

          {/* <div className="flex flex-col space-y-2 items-center w-full">
            <TelegramLoginButton />
            <Typography variant="body1">или</Typography>
            <VkLoginButton />
          </div> */}
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;

// Add type declarations for window properties
declare global {
  interface Window {
    firebase: any; // Replace 'any' with your Firebase type if available
    firebaseui: {
      auth: {
        AuthUI: {
          getInstance(): any;
          new (auth: any): any;
        };
      };
    };
  }
}
