import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import React from 'react';
import { DateTimeSlotVM } from '../../viewModels';
import { Badge } from '@mui/material';
import { DateTime } from 'luxon';

interface DayCalendarSelectorProps {
  onChange: (date: DateTime | undefined) => void;
  availabilities: DateTimeSlotVM[];
  value: DateTime | undefined;
  disableEmptyDates?: boolean;
}

function ServerDay(
  props: PickersDayProps<Date> & { availabilities?: DateTimeSlotVM[] },
) {
  const { day, outsideCurrentMonth, ...other } = props;

  const dayAvailabilities = props.availabilities?.filter((x) =>
    x.start.hasSame(DateTime.fromJSDate(props.day), 'day'),
  ) ?? [];

  return (
    <Badge
      key={props.day.toString()}
      sx={{ zIndex: 0 }}
      overlap="circular"
      color="secondary"
      badgeContent={dayAvailabilities.length}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export const DayCalendarSelector: React.FC<DayCalendarSelectorProps> = (
  props,
) => {
  return (
    <>
      <DateCalendar
        disablePast
        onMonthChange={(date) => props.onChange(date ? DateTime.fromJSDate(date) : undefined)}
        showDaysOutsideCurrentMonth
        shouldDisableDate={props.disableEmptyDates ? (date => props.availabilities.filter(x => x.start.hasSame(DateTime.fromJSDate(date), 'day')).length === 0) : undefined}
        value={props.value?.toJSDate() ?? null}
        onChange={(date) => props.onChange(date ? DateTime.fromJSDate(date) : undefined)}
        slots={{
          day: ServerDay,
        }}
        maxDate={props.disableEmptyDates && props.availabilities.length > 0 ?
          DateTime.fromMillis(Math.max(...props.availabilities.map(x => x.start.toMillis()))).toJSDate() : undefined}
        slotProps={{
          day: {
            availabilities: props.availabilities,
          } as any,
        }}
      />
    </>
  );
};
