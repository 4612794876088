import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { ViewField } from './ViewEditControl';
import { useTranslation } from 'react-i18next';

interface ViewDetailsSectionProps {
  fields: ViewField[];
}

const ViewDetailsSection: React.FC<ViewDetailsSectionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <List sx={{ width: 1, px: 0, py: 0 }}>
        {props.fields.map((field, index) => (
          <React.Fragment key={index}>
            <ListItem
              sx={{
                px: 0,
                '&.MuiListItem-root': {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                cursor: field.onClick ? 'pointer' : 'default',
              }}
              onClick={field.onClick}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                {field.icon}
              </ListItemIcon>
              <ListItemText
                primary={field.value || t('No')}
                secondary={field.label}
                primaryTypographyProps={{
                  style: {
                    color: field.value ? 'inherit' : 'gray',
                  },
                }}
              />

              {field.button && (
                <IconButton onClick={field.button.onClick}>
                  {field.button.icon}
                </IconButton>
              )}
            </ListItem>
            {index < props.fields.length - 1 && (
              <Divider sx={{ borderColor: 'divider', ml: 5 }} />
            )}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

export default ViewDetailsSection;
