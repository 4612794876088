import React, { useEffect } from 'react';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import UserSettingsEditor from './SettingsUserEditor';
import useUser from '../../queryHelpers/useUser';
import * as yup from 'yup';
import useUpdateUserSettings from '../../queryHelpers/useUpdateUserSettings';
import { useEditForm } from '../../hooks/useEditForm';
import { getTimezoneDisplayText } from '../TimezoneSelector';
import { UserVM } from '../../viewModels';
import { useTranslation } from 'react-i18next';
import { Language } from '@mui/icons-material';

type UserSettingsViewEditProps = {
  userId: string;
};

export type SettingsUserFormInputs = Pick<
  UserVM,
  'name' | 'email' | 'timezone' | 'language'
>;

const SettingsUserViewEdit: React.FC<UserSettingsViewEditProps> = (props) => {
  const userQuery = useUser(props.userId);
  const { t } = useTranslation();
  const SettingsUserFormSchema = yup
    .object({
      name: yup.string().required('Enter a name'),
      email: yup
        .string()
        .email('Enter a valid email address')
        .required('Enter an email address'),
      timezone: yup.string().required('Select a timezone'),
      language: yup.string().required('Select a language'),
    })
    .required();

  const { form, handleCancel } = useEditForm<SettingsUserFormInputs>({
    defaultValues: userQuery.data,
    formSchema: SettingsUserFormSchema,
  });

  useEffect(() => {
    if (userQuery.data) {
      form.reset(userQuery.data);
    }
  }, [form, userQuery.data]);

  const updateUserSettingsMutation = useUpdateUserSettings();

  const onSubmit = (data: SettingsUserFormInputs) => {
    updateUserSettingsMutation.mutate({
      ...userQuery.data!,
      ...data,
      id: props.userId,
    });
  };

  return (
    <ViewEditControl
      title={t('Personal information')}
      editMode="inline"
      viewFields={[
        {
          icon: <PersonIcon />,
          label: t('Name'),
          value: userQuery.data?.name || '',
        },
        {
          icon: <EmailIcon />,
          label: t('Email'),
          value: userQuery.data?.email || '',
        },
        {
          icon: <AccessTimeIcon />,
          label: t('Timezone'),
          value: (() => {
            const timezone = getTimezoneDisplayText(
              userQuery.data?.timezone || '',
            );
            return timezone ? `(${timezone.offset}) ${t(timezone.name)}` : '';
          })(),
        },
        {
          icon: <Language />,
          label: t('Language'),
          value: userQuery.data?.language ? t(userQuery.data.language) : '',
        },
      ]}
      editor={<UserSettingsEditor form={form} />}
      editorIsValid={form.formState.isValid}
      onCancel={handleCancel}
      onSave={form.handleSubmit(onSubmit)}
    />
  );
};

export default SettingsUserViewEdit;
