const generateTimes = (start: number) => {
  const result = [];
  let time = start;

  for (let i = 0; i < (24 * 60) / 5; i++) {
    result.push(time);
    time += 5;
  }

  result.push(0);

  return result;
};

export const Times = generateTimes(0);
