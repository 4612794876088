import { Box, Grid, MenuItem, Select } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { BookingVM } from '../viewModels';
import useExperiences from '../queryHelpers/useExperiences';
import { useTranslation } from 'react-i18next';

type Props = {
  form: UseFormReturn<ExperienceSelectorFormInputs>;
};

export type ExperienceSelectorFormInputs = Pick<BookingVM, 'experienceId'>;

const ExperienceSelector: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const experiencesQuery = useExperiences();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            {...props.form.register('experienceId')}
            autoWidth
            size="small"
            fullWidth
            displayEmpty
            defaultValue={''}
          >
            <MenuItem value="">
              <em>{t('Select service')}</em>
            </MenuItem>
            {experiencesQuery?.data?.map((experience) => (
              <MenuItem key={experience.id} value={experience.id}>
                {experience.summary.title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExperienceSelector;
