import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ImagePosition = 'left' | 'right';

type HowToUseSectionItemProps = {
  title: string;
  description: string;
  image: string;
  imagePosition: ImagePosition;
};

const HowToUseSectionItem: React.FC<HowToUseSectionItemProps> = (props) => {
  const flexDirection =
    props.imagePosition === 'right' ? 'md:flex-row' : 'md:flex-row-reverse';
  return (
    <Card elevation={0} sx={{ maxWidth: 800 }}>
      <CardContent>
        <div
          className={
            'flex flex-col items-center text-center gap-4 md:gap-11 ' +
            flexDirection
          }
        >
          <div className="flex-grow md:text-left sm:text-center">
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color="primary"
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </div>
          <div>
            <Box
              sx={{ width: 150 }}
              className="flex items-center flex-grow-0 justify-center"
            >
              <img
                src={props.image}
                alt="logo"
                width={'100'}
                className="transform rounded"
              />
            </Box>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const HowToUseSection = () => {
  const { t } = useTranslation();
  const items: Omit<HowToUseSectionItemProps, 'imagePosition'>[] = [
    {
      title: t('Register'),
      description: t('Register.Description'),
      image: '/images/landing/HowToStep1.png',
    },
    {
      title: t('Create a service'),
      description: t('Create a service.Description'),
      image: '/images/landing/HowToStep2.png',
    },
    {
      title: t('Share the link for online booking'),
      description: t('Share the link for online booking.Description'),
      image: '/images/landing/HowToStep3.png',
    },
    {
      title: t('Get a notification about the new appointment.'),
      description: t('Get a notification about the new appointment..Description'),
      image: '/images/landing/HowToStep4.png',
    },
    {
      title: t('View all appointments'),
      description: t('View all appointments.Description'),
      image: '/images/landing/HowToStep5.png',
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">
        {t('Open online booking in {number} steps', { number: items.length })}
      </Typography>
      <div className="flex flex-col justify-center gap-6">
        {items.map((item, index) => (
          <HowToUseSectionItem
            key={item.title}
            title={`${index + 1}. ${item.title}`}
            description={item.description}
            image={item.image}
            imagePosition={index % 2 === 0 ? 'right' : 'left'}
          />
        ))}
      </div>
    </div>
  );
};

export default HowToUseSection;
