import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col justify-center items-center pt-12 gap-2">
      <img src="/images/logo.svg" alt="logo" width={100} />
      <Typography variant="h3">Minutus</Typography>
      <Typography variant="h6">{t('Online booking for your clients')}</Typography>
      <Typography variant="caption" maxWidth={400} className='text-center'>
        {t('Set up your services, schedule and open Online booking for your clients')}
      </Typography>
    </div>
  );
};

export default HeroSection;
