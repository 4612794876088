// site/theme.ts

import { createTheme, ThemeOptions } from '@mui/material/styles';
import * as rootTheme from '../../theme';
import { merge } from 'lodash';

const customTheme: ThemeOptions = {
  palette: {
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
      },
    },
  },
};

// Default theme https://mui.com/material-ui/customization/default-theme/
// Standard https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
const theme = createTheme({
  ...merge({}, rootTheme.default, customTheme),
});

export default theme;
