import React from 'react';
import WorkIcon from '@mui/icons-material/WorkOutline';
import CommentBankIcon from '@mui/icons-material/CommentBankOutlined';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import { useTranslation } from 'react-i18next';

type BookingScheduleViewEditProps = {
  bookingId: string;
};

const BookingSummaryViewEdit: React.FC<BookingScheduleViewEditProps> = (
  props,
) => {
  const { t } = useTranslation();
  const bookingQuery = useBooking(props.bookingId);
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId as string,
    enabled: !!bookingQuery.data,
  });

  return (
    <>
      <ViewEditControl
        title={t('Appointment')}
        editMode="inline"
        viewFields={[
          {
            icon: <WorkIcon />,
            label: t('Service'),
            value: experienceQuery.data?.summary.title,
          },
          {
            icon: <CommentBankIcon />,
            label: t('Wishes'),
            value: bookingQuery.data?.wishes || t('Not specified'),
          },
        ]}
        onSave={async (e) => {}}
        onCancel={() => {}}
      />
    </>
  );
};

export default BookingSummaryViewEdit;
