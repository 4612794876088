import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { RefreshGoogleIntegration } from '../firebaseFunctions';

interface UseRefreshGoogleIntegrationVariables {
  accountId: string;
}

export default function useSetGoogleIntegration() {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    unknown,
    Error,
    UseRefreshGoogleIntegrationVariables,
    unknown
  >({
    mutationFn: async (data: UseRefreshGoogleIntegrationVariables) => {
      return await RefreshGoogleIntegration();
    },

    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.accounts, variables.accountId],
      });
    },
  });

  return mutation;
}
