import { useQuery } from '@tanstack/react-query';
import { useGoogleAccessToken } from './useGoogleAccessToken';
import { useTranslation } from 'react-i18next';

export interface GoogleUserInfo {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
}

export function useGoogleUserInfo() {
  const { t } = useTranslation();
  const googleAccessToken = useGoogleAccessToken();
  return useQuery({
    queryKey: ['google', 'me', { accessToken: googleAccessToken.accessToken }],
    queryFn: async () => {
      if (!googleAccessToken.accessToken) {
        return null;
      }
      const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${googleAccessToken.accessToken}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(t('Network response was not ok'));
      }
      const data = await response.json();
      return data as GoogleUserInfo;
    },
  });
}
