import React from 'react';
import { Checkbox, FormControlLabel, Grid, Slider } from '@mui/material';
import {
  pluralBookings,
  pluralDays,
  pluralTime,
} from '../../helpers/pluralHelpers';
import TimeSlider from './TimeSlider';
import { Label } from '../../styled/Label';
import { LimitSettingsFormInputs } from './LimitSettingsViewEdit';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface LimitSettingsEditorProps {
  register: UseFormRegister<LimitSettingsFormInputs>;
  errors: FieldErrors<LimitSettingsFormInputs>;
  watch: UseFormWatch<LimitSettingsFormInputs>;
  control: Control<LimitSettingsFormInputs>;
  autoLink?: boolean;
}

const LimitSettingsEditor: React.FC<LimitSettingsEditorProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>
          {t('The appointment is open for the next {time}', {
            time: pluralDays(props.watch('futureBookingDaysLimit')!),
          })}
        </Label>
        <Slider
          {...props.register('futureBookingDaysLimit')}
          step={1}
          min={1}
          max={90}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>
          {t('The appointment is open {time} before the start', {
            time: pluralTime(props.watch('minimumNoticePeriod')!),
          })}
        </Label>
        <Controller
          control={props.control}
          name="minimumNoticePeriod"
          defaultValue={0}
          render={({ field }) => (
            <TimeSlider
              value={field.value!}
              onChange={(e, value) => field.onChange(value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>
          {t('A total of {bookings} appointments a day', {
            bookings: pluralBookings(props.watch('dailyBookingLimit')!),
          })}
        </Label>
        <Slider
          {...props.register('dailyBookingLimit')}
          step={1}
          min={1}
          max={20}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>
          {Number(props.watch('gapBetweenEvents'))
            ? t('{time} gap between appointments', {
                time: pluralTime(props.watch('gapBetweenEvents')!),
              })
            : t('Without a break')}
        </Label>
        <Slider
          {...props.register('gapBetweenEvents')}
          step={5}
          min={0}
          max={120}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...props.register('onlyConsecutiveReservations')}
              defaultChecked={props.watch('onlyConsecutiveReservations')}
            />
          }
          label={t('Allow making bookings only next to existing appointments')}
        />
      </Grid>
    </Grid>
  );
};

export default LimitSettingsEditor;
