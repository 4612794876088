import { IosShare, Share } from '@mui/icons-material';

export const AdaptiveShareIcon: React.FC = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return <Share />; // Replace with actual Android share icon
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return <IosShare />; // Replace with actual iOS share icon
  } else {
    return <Share />; // Replace with actual Windows share icon
  }
};
