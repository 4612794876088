import { AppBar, Toolbar, Button, Tabs, Tab, Typography, Menu, MenuItem } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLanguage } from '../../contexts/LanguageContex';
import { AppLanguage } from 'shared-ts/entities';

const LandingLayout: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Use URLSearchParams to parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');
  const isTestMode = mode === 'test';

  useEffect(() => {
    // Ensure the correct language is set on mount
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (lang?: string) => {
    setAnchorEl(null);
    if (lang) {
      changeLanguage(lang as AppLanguage);
    }
  };

  return (
    <>
      <AppBar position="fixed" variant="elevation" elevation={1}>
        <Toolbar variant="dense" className="bg-white text-black">
          <div className="flex-grow">
            <Tabs value={routes.landing} aria-label="basic tabs example">
              <Tab
                href={routes.landing}
                label={t('MainMenu')}
                value={routes.landing}
              />
            </Tabs>
          </div>
          <div className='flex flex-row items-center space-x-2'>
            <Button
              onClick={handleLanguageClick}
              className="min-w-[80px] normal-case"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                padding: '4px 8px',
                color: 'inherit',
                backgroundColor: 'transparent'
              }}
            >
              {language.toUpperCase()}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleLanguageClose()}
              PaperProps={{
                elevation: 0,
                sx: {
                  minWidth: '80px',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  mt: 1
                },
              }}
            >
              <MenuItem onClick={() => handleLanguageClose('en')}>EN</MenuItem>
              <MenuItem onClick={() => handleLanguageClose('ru')}>RU</MenuItem>
            </Menu>
            {!isTestMode && <Typography className='italic text-gray-400'>{t('Coming soon...')}</Typography>}
            <Button
              component={Link}
              variant="contained"
              to={isTestMode ? routes.login : routes.landing}
              color="primary"
              disabled={!isTestMode}
            >
              {t('Login')}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className="pt-2 mt-12 px-4">
        <Outlet />
      </div>
    </>
  );
};

export default LandingLayout;
