import React from 'react';
import { TextField, Grid, FormHelperText, Autocomplete, Switch } from '@mui/material';
import { Label } from '../../styled/Label';
import { Controller, UseFormReturn } from 'react-hook-form';
import { GoogleCalendarIntegrationFormInputs } from './GoogleCalendarIntegrationViewEdit';
import useGoogleCalendars from '../../queryHelpers/useGoogleCalendars';
import { useTranslation } from 'react-i18next';

interface GoogleCalendarIntegrationEditorProps {
  form: UseFormReturn<GoogleCalendarIntegrationFormInputs>;
}

export const GoogleCalendarIntegrationEditor: React.FC<GoogleCalendarIntegrationEditorProps> = ({
  form,
}) => {
  const { t } = useTranslation();
  const googleCalendarsQuery = useGoogleCalendars();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Создавать записи в этом календаре</Label>
        <Controller
          name="calendarIdForNewEvents"
          control={form.control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={googleCalendarsQuery.data || []}
              getOptionLabel={(option) => option.summary!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!form.formState.errors.calendarIdForNewEvents}
                  size="small"
                  fullWidth
                />
              )}
              onChange={(_, newValue) => field.onChange(newValue ? newValue.id : null)} // Allow null value
              value={!field.value ? null : googleCalendarsQuery.data?.find(x => field.value?.indexOf(x.id!) !== -1)}
            />
          )}
        />
        <FormHelperText error>{form.formState.errors.calendarIdForNewEvents?.message}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Check availability in these calendars')}</Label>
        <Controller
          name="calendarIdsForAvailability"
          control={form.control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={googleCalendarsQuery.data || []}
              getOptionLabel={(option) => option.summary!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                />
              )}
              onChange={(_, newValue) => field.onChange(newValue.map(cal => cal.id) as string[])}
              value={!field.value ? [] : googleCalendarsQuery.data?.filter(x => field.value?.indexOf(x.id!) !== -1)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Invite these participants')}</Label>
        <Controller
          name="invitees"
          control={form.control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              freeSolo
              options={[]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  placeholder={t('Enter email and press Enter')}
                />
              )}
              onChange={(_, newValue) => field.onChange(newValue)}
              value={field.value || []}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>{t('Create Google Meet for video calls')}</Label>
        <Controller
          name="createGoogleMeetLink"
          control={form.control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value ?? false}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default GoogleCalendarIntegrationEditor;
